import moment from "moment";

export const dateToFormatedTime = (time: string, date: string): string => {
  let dateWithTime = new Date(date);
  dateWithTime.setHours(
    parseInt(time.substring(0, 2)),
    parseInt(time.substring(3, 5))
  );
  return moment(dateWithTime).format("hh:mm A");
};
export class CalendarColorsCalendarColors {
public arr = [
  'bg-blue-200',
  'bg-red-200',
  'bg-orange-100',
  'bg-violet-500/15',
  'bg-green-200'
]
public randomIndex = Math.floor(Math.random() * 5) + 1
  static arr: any;
getRandomColor (){
  return this.arr[this.randomIndex-1]
}
}