import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import Spinner from "../spinner";
import LeaveTypes from "../modals/leaveTypes";

interface LeaveProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const LeavesTypes: React.FC<LeaveProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [leaveTypes, setLeaveTypes] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);

  const fetchLeaveTypes = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(`/leaves/types?page=${currentPage}`, {
        method: "GET",
      });
      setLeaveTypes(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch leaves:", error);
    }
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, [currentPage, count]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <LeaveTypes
            isModalVisible={isModalVisible}
            setIsModalVisible={setModalVisible}
            fetchLeaves={fetchLeaveTypes}
          />
          <DeleteModal
            route="leaves/types"
            visibilityState={deleteRequestModal}
            setState={setDeleteRequestModal}
            fetchAllCall={fetchLeaveTypes}
            //content={""}
          />
          {!!isLoading ? (
            <Spinner />
          ) : (
            <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
              <TableHead className="hidden lg:table-header-group w-full text-center">
                <TableRow className="bg-white w-full text-center">
                  <TableHeader className="!outline-none !border-b-0 ">
                    <div className="flex items-center justify-center text-black ">
                      {t("Number")}
                    </div>
                  </TableHeader>
                  <TableHeader className="!outline-none !border-b-0">
                    <div className="flex items-center justify-center text-black">
                      {t("Types")}
                    </div>
                  </TableHeader>
                  <TableHeader className="!outline-none !border-b-0 ">
                    <div className="flex items-center justify-center text-black">
                      {t("Allowed Count")}
                    </div>
                  </TableHeader>
                  <TableHeader className="!outline-none !border-b-0 ">
                    <div className="flex items-center justify-center text-black">
                      {t("Notes")}
                    </div>
                  </TableHeader>
                  <TableHeader className="!outline-none !border-b-0 text-black"></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody className="w-full text-center">
                {Array.isArray(leaveTypes) &&
                  leaveTypes?.map((leaveType, index) => {
                    const rowNumber = index + 1 + (currentPage - 1) * count;
                    return (
                      <TableRow
                        key={leaveType.id}
                        className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full text-center cursor-pointer hover:bg-slate-100"
                      >
                        <TableCell className="lg:hidden font-bold bg-gray-100">
                          {t("Number")}
                        </TableCell>
                        <TableCell>{rowNumber}</TableCell>
                        <TableCell className="lg:hidden font-bold bg-gray-100 ">
                          {t("Types")}
                        </TableCell>
                        <TableCell className="w-full ">
                          {leaveType?.type}
                        </TableCell>
                        <TableCell className="lg:hidden font-bold bg-gray-100 ">
                          {t("Allowed Count")}
                        </TableCell>
                        <TableCell className="w-full">
                          {leaveType?.allowed_count}
                        </TableCell>
                        <TableCell className="lg:hidden font-bold bg-gray-100 ">
                          {t("Notes")}
                        </TableCell>
                        <TableCell className="w-full text-center overflow-hidden text-ellipsis whitespace-nowrap max-w-[300px]">
                         
                          <div className="flex items-center justify-center">
                          {leaveType?.note}
                    </div>
                        </TableCell>

                        <TableCell className="flex justify-end w-full">
                          <div
                            onClick={() => setModalVisible(leaveType._id)}
                            className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                          >
                            <img
                              alt="editIcon"
                              src={editIcon}
                              className="w-6 h-6"
                            />
                          </div>
                          <div
                            onClick={() =>
                              setDeleteRequestModal(leaveType?._id)
                            }
                            className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                          >
                            <img
                              alt="deleteIcon"
                              src={deleteIcon}
                              className="w-6 h-6"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </>
  );
};

export default LeavesTypes;
