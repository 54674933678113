import React, { useState } from "react";
import close from "../../assets/close.png";
import { ToastOptions, toast } from "react-toastify";
import deleteToast from "../../assets/deleteToast.png";
import trash from "../../assets/trash.svg";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import deleteIcon2 from "../../assets/cruds/deletec.svg";

const showToast = (id: string | number, t: any) => {
  const toastProps: ToastOptions = {
    position: "bottom-right",
    className: "foo-bar",
    icon: (
      <img
        alt="deleteToast"
        src={deleteIcon2}
        className="w-[40px] min-w-[40px] max-w-[40px]"
      />
    ),
  };

  toast.error(
    <div className="msg-container ml-5">
      <p className="msg-title font-[700] text-[18px] text-[black]">
        {t("Deleted successfully")}
      </p>
      <p className="msg-description text-[12px] text-[#5d6561]">
        {t("Request id")} {id} {t("was deleted")}
      </p>
    </div>,
    toastProps
  );
};

type DeleteOnCallsModalProps = {
  OnDelete: any;
  visibilityState: boolean | string | number;
  setState: (state: boolean | string | number) => void;
};

const DeleteOnCallsModal: React.FC<DeleteOnCallsModalProps> = ({
  OnDelete,
  visibilityState,
  setState,
}) => {
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const { t } = useTranslation();

  const deleteData = async () => {
    setIsDecline(true);
    OnDelete();
    setState(false);
  };

  return visibilityState ? (
    <main
      onClick={() => setState(false)}
      className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-[1]"
    >
      <div className="container my-auto px-4">
        <div className="max-w-[90%] sm:max-w-[400px] m-auto w-full bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <div className="flex justify-end">
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={() => setState(false)}
            >
              <img src={close} alt="close" className="w-[20px] h-[20px]" />
            </button>
          </div>

          <div className="text-center">
            <img src={trash} alt="delete" className="w-[60px] h-[60px] mx-auto my-4" />
            <div className="text-[#101010] text-lg font-extrabold mb-4">
              Are you want to Remove Schedule?
            </div>
          </div>

          <div className="flex justify-center space-x-2 mt-4">
            <button
              type="button"
              onClick={() => {
                setState(false);
                setIsDecline(true);
              }}
              className={`w-[120px] h-[40px] rounded-md border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] ${isDecline ? "text-[#389365] border-[#00A843]" : ""
                } text-[16px] font-semibold`}
            >
              {t("Cancel")}
            </button>
            <button
              type="button"
              onClick={deleteData}
              className={`w-[120px] h-[40px] rounded-md border border-[#FF0000] text-[#FF0000] hover:border-[#FF0000] hover:text-[#FF0000] ${isDecline ? "text-[#FF0000] border-[#FF0000]" : ""
                } text-[16px] font-semibold`}
            >
              {t("Delete")}
            </button>
          </div>
        </div>
      </div>
    </main>
  ) : null;
};

export default DeleteOnCallsModal;
