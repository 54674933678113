import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import Leave from "../modals/leave";
import DeleteModal from "../modals/deleteModal";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import Spinner from "../spinner";
import moment from "moment";

interface LeaveProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean | string | number>>;
}

const Leaves: React.FC<LeaveProps> = ({ isModalVisible, setModalVisible }) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const [leaves, setLeaves] = useState<any[]>([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState<boolean | string | number>(false);

  const fetchLeaves = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(`/leaves?page=${currentPage}`, { method: "GET" });
      setLeaves(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit);
    } catch (error) {
      console.error("Failed to fetch leaves:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLeaves();
  }, [currentPage, count]);

  return (
    <>
      {isLoading ? <Spinner /> : (
        <>
          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
            <TableHead className="hidden lg:table-header-group w-full text-center">
              <TableRow className="bg-white w-full">
                <TableHeader className="text-black">{t("Number")}</TableHeader>
                <TableHeader className="text-black">{t("Leave Type")}</TableHeader>
                <TableHeader className="text-black">{t("Start Date")}</TableHeader>
                <TableHeader className="text-black">{t("End Date")}</TableHeader>
                <TableHeader className="text-black">{t("Status")}</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className="w-full">
              {leaves.map((each, index) => {
                const rowNumber = index + 1 + (currentPage - 1) * count;
                //console.log("Type:", each);
                return (
                  <TableRow key={each?._id} className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full text-center cursor-pointer hover:bg-slate-100">
                    <TableCell className="lg:hidden font-bold bg-gray-100">{t("Number")}</TableCell>
                    <TableCell>{rowNumber}</TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100">{t("Leave Type")}</TableCell>
                    <TableCell>{each?.type?.type || "N/A"}</TableCell>


                    <TableCell className="lg:hidden font-bold bg-gray-100">{t("Start Date")}</TableCell>
                    <TableCell>{moment(each?.start_date).format("ddd, DD MMM YYYY")}</TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100">{t("End Date")}</TableCell>
                    <TableCell>{moment(each?.end_date).format("ddd, DD MMM YYYY")}</TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100">{t("Status")}</TableCell>
                    <TableCell>{each?.status}</TableCell>
                    <TableCell className="flex justify-end w-full">
                      <div
                        onClick={() => setModalVisible(each?._id)}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img src={editIcon} alt="Edit" className="w-6 h-6" />
                      </div>
                      <div
                        onClick={() => setDeleteRequestModal(each?._id)}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img src={deleteIcon} alt="Delete" className="w-6 h-6" />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Leave isModalVisible={isModalVisible} setIsModalVisible={setModalVisible} fetchLeaves={fetchLeaves} />
          <DeleteModal route="leaves" visibilityState={deleteRequestModal} setState={setDeleteRequestModal} fetchAllCall={fetchLeaves} />
        </>
      )}
    </>
  );
};

export default Leaves;
