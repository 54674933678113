import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import fetchWithToken from "../../utils/api";
import { useSelector } from "react-redux";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { t } from "i18next";
import { Select } from "@headlessui/react";

const ResponsiveRotationTable = ({ userDepartment }) => {
  const Myuser = useSelector((state) => state?.user?.user);
  const userRole = useSelector((state) => state?.user?.user?.role);
  const [rowSelections, setRowSelections] = useState([]);
  const [currentRotation, setCurrentRotation] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [userShift, setUserShift] = useState();
  const userId = useSelector((state) => state?.user?.user?._id);
  const [idToColorMap, setIdToColorMap] = useState({});
  const colors = [
    "bg-green-100", "bg-red-100", "bg-yellow-100", "bg-blue-100", "bg-purple-100",
    "bg-pink-100", "bg-teal-100", "bg-indigo-100", "bg-orange-100", "bg-gray-100"
  ];

  const generateData = () => {
    const weeksInYear = 52;
    const blocks = 13;
    const weeksPerBlock = weeksInYear / blocks;
    const data = [];

    for (let i = 0; i < blocks; i++) {
      const startWeek = i * weeksPerBlock + 1;
      const endWeek = (i + 1) * weeksPerBlock;

      const startDate = moment().startOf("year").add(startWeek - 1, "weeks").format("DD-MM");
      const endDate = moment().startOf("year").add(endWeek - 1, "weeks").add(6, "days").format("DD-MM");

      data.push({
        block: i + 1,
        week: `${Math.floor(startWeek)}-${Math.floor(endWeek)}`,
        date: `${startDate} - ${endDate}`,
      });
    }

    return data;
  };

  const tableData = generateData();

  const calculateRemainingLimits = () => {
    const limits = {};
    userDepartment.forEach((dept) => {
      const selectedCount = currentRotation.filter(
        (item) => item.sub_department_id === dept.value
      ).length;
      limits[dept.value] = Math.max(0, dept.user_level_limit - selectedCount);
    });
    return limits;
  };

  const [departmentLimits, setDepartmentLimits] = useState({});

  useEffect(() => {
    setDepartmentLimits(calculateRemainingLimits());
  }, [currentRotation, userDepartment]);

  const handleSelectChange = (id, index, value) => {
    const updatedSelections = [...rowSelections];
    const [startDate, endDate] = tableData[index].date.split(" - ");

    const formattedStartDate = moment(startDate, "DD-MM").add(1, "years").format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "DD-MM").add(1, "years").format("YYYY-MM-DD");

    const rowData = {
      block: tableData[index].block,
      week: tableData[index].week,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      subDepartment: value,
    };

    updatedSelections[index] = rowData;
    setRowSelections(updatedSelections);
    saveSelectedOption(rowData, id);
  };

  const formatRotationData = (rotationData) => {
    return rotationData.map((item) => {
      const startDate = moment(item.start_date);
      const endDate = moment(item.end_date);

      const startWeek = startDate.week();
      const endWeek = endDate.week();
      const block = Math.ceil(startWeek / 4);

      return {
        _id: item._id,
        block: block,
        week: `${startWeek}-${endWeek}`,
        date: `${startDate.format("DD-MM")} - ${endDate.format("DD-MM")}`,
        sub_department_id: item.sub_department_id,
      };
    });
  };

  const fetchUserScheduleRotation = useCallback(async () => {
    if (!selectedUser) return
    try {
      const data = await fetchWithToken(`/schedules-rules/rotations?user_id=${selectedUser}`, {
        method: "GET",
      });

      const formattedData = formatRotationData(data?.data || []);
      setCurrentRotation(formattedData);
    } catch (error) {
      console.error(t("errorFetchingShift"), error);
    }
  }, [selectedUser, formatRotationData, setCurrentRotation, t]);

  const fetchUserScheduleId = async () => {
    try {
      const data = await fetchWithToken("/shifts/rotation", {
        method: "GET",
      });
      setUserShift(data?.data[0]);
    } catch (error) {
      console.error(t("errorGettingShift"));
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await fetchWithToken("/users?limit=100", {
        method: "GET",
      });
      setUsers(data?.data);
    } catch (error) {
      console.error(t("errorGettingShift"));
    }
  };

  const saveSelectedOption = async (data, id) => {
    setModalVisible(false);
    const bodyData = {
      user_id: selectedUser,
      shift_id: userShift?._id,
      daysOfWeek: [1, 2, 3, 4, 5],
      sub_department_id: data?.subDepartment,
      start_date: data?.start_date,
      end_date: data?.end_date,
    };
    try {
      await fetchWithToken(id ? `/schedules-rules/${id}` : "/schedules-rules", {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      fetchUserScheduleRotation();
      SuccessToast(
        t("Accepted"),
        t(`Your Rotation was saved successfully`),
        t
      );
    } catch (err) {
      console.error(err);
      setModalVisible(true);
      FailToast(err?.message);
    }
  };

  const handleDeleteOption = async (Id) => {
    setModalVisible(false);
    try {
      await fetchWithToken(`/schedules-rules/${Id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchUserScheduleRotation();
      SuccessToast(
        t("Done"),
        t(`Your Rotation was Deleted successfully`),
        t
      );
    } catch (err) {
      console.error(err);
      setModalVisible(true);
      FailToast("error Deleting the Rotating");
    }
  };

  const assignColorsToSubDepartments = () => {
    let colorMap = {};
    let colorIndex = 0;

    currentRotation.forEach((item) => {
      if (item.sub_department_id && !colorMap[item.sub_department_id]) {
        colorMap[item.sub_department_id] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });

    setIdToColorMap(colorMap);
  };

  useEffect(() => {
    if (users) {
      setSelectedUser(Myuser?._id)
    }
  }, [])

  useEffect(() => {
    fetchUserScheduleRotation();
    fetchUserScheduleId();
    fetchUsers()
  }, [selectedUser]);

  useEffect(() => {
    if (currentRotation.length) {
      assignColorsToSubDepartments();
    }
  }, [currentRotation]);


  return (
    <div className="w-full px-4 mx-auto flex flex-col lg:flex-row gap-6">
      {/* Left Side - Table */}
      <div className="lg:w-2/3 w-full overflow-x-auto border border-gray-300 rounded-lg p-4">
        <table className="table-auto border-collapse border border-gray-300 w-full text-sm text-gray-700">
          <thead>
            <tr>
              <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("block")}</th>
              <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("date")}</th>
              <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("subDepartment")}</th>
              <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("action")}</th>
            </tr>
          </thead>

          <tbody>
            {tableData.map((row, index) => {
              const rotation = currentRotation?.find((rotationItem) => rotationItem.date === row.date);
              const rowColor = rotation ? idToColorMap[rotation.sub_department_id] : "";

              return (
                <tr
                  key={index}
                  id={rotation ? rotation._id : ""}
                  className={`border-b transition duration-300 ${rowColor ? rowColor : "hover:bg-gray-100"}`}
                >
                  <td className="border border-gray-300 p-3 text-center">{row.block}</td>
                  <td className="border border-gray-300 p-3 text-center">{row.date}</td>

                  <td className="border border-gray-300 p-3">
                    <select
                      className="w-full h-10 border border-gray-300 rounded-lg px-3 focus:ring-2 focus:ring-darkBlue focus:outline-none"
                      name="subDepartment"
                      value={rotation ? rotation.sub_department_id : ""}
                      onChange={(e) => handleSelectChange(rotation ? rotation._id : "", index, e.target.value)}
                    >
                      <option value="" disabled={!rotation}>
                        {t("selectSubDepartment")}
                      </option>
                      {userDepartment.map((dept) => {
                        const isAlreadySelected = currentRotation.some((item) => item.sub_department_id === dept.value);
                        const remainingLimit = departmentLimits[dept.value] || 0;

                        return (
                          <option
                            key={dept.value}
                            value={dept.value}
                            className={isAlreadySelected ? "text-gray-400 font-semibold" : ""}
                            disabled={remainingLimit === 0}
                          >
                            {dept.label}
                          </option>
                        );
                      })}
                    </select>
                  </td>

                  <td className="p-3 flex flex-row justify-center items-center">
                    <button
                      className="w-8 h-8 flex items-center justify-center bg-red-500 text-white font-bold rounded-full transition duration-300 hover:bg-red-600 shadow-md"
                      onClick={() => handleDeleteOption(rotation ? rotation._id : "")}
                    >
                      ✖
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Right Side - Sub-department Available */}
      <div className="lg:w-1/3 w-full border border-gray-300 rounded-lg p-4 bg-gray-100">
        <h2 className="text-lg font-bold mb-4">{t("Sub-department Available")}</h2>
        {userRole === "admin" && (
          <div className="flex items-center justify-start gap-5 mb-4">
            <div className="font-medium">{t("Select User")}</div>
            <Select
              className="w-full sm:w-[150px] h-[40px] border border-[#7E8581] rounded-md sm:mr-3"
              name="selectedUser"
              onChange={(e) => setSelectedUser(e.target.value)}
              value={selectedUser}
            >
              {users?.map((user) => (
                <option key={user?._id} value={user?._id}>
                  {user?.first_name} {user?.last_name}
                </option>
              ))}
            </Select>
          </div>
        )}

        <div className="flex flex-col gap-3">
          {userDepartment.map((dept) => {
            const remainingLimit = departmentLimits[dept.value] || 0;

            return (
              <div
                key={dept.value}
                className="flex items-center justify-between bg-white p-3 rounded shadow-md"
              >
                <span className="text-sm font-medium">{dept.label}</span>
                <span className="text-sm font-bold text-blue-600">{remainingLimit}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>

    // <div className="w-full px-4 mx-auto ">
    //   <div className="mb-4">
    //     <h2 className="text-lg font-bold mb-2">{t("Sub-department Available")}</h2>
    //     {userRole === "admin" && (
    //       <div className="flex items-center justify-start gap-5">
    //         <div>Select User</div>
    //         <Select
    //           className="w-full sm:w-[150px] h-[40px] my-2 border border-[#7E8581] rounded-md sm:mr-3"
    //           name="selectedUser"
    //           onChange={(e) => setSelectedUser(e.target.value)}
    //           value={selectedUser}
    //         >
    //           {users?.map((user) => (
    //             <option key={user?._id} value={user?._id}>
    //               {user?.first_name} {user?.last_name}
    //             </option>
    //           ))}
    //         </Select>
    //       </div>
    //     )}

    //     <div className="flex flex-wrap gap-4">
    //       {userDepartment.map((dept) => {
    //         const selectedCount = currentRotation.filter(
    //           (item) => item.sub_department_id === dept.value
    //         ).length;
    //         const remainingLimit = departmentLimits[dept.value] || 0;

    //         return (
    //           <div
    //             key={dept.value}
    //             className="flex items-center justify-between bg-gray-100 p-2 rounded shadow-md"
    //           >
    //             <span className="text-sm font-medium">{dept.label}</span>
    //             <span className="text-sm font-bold text-blue-600 ml-5">
    //               {remainingLimit}
    //             </span>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    //   <div className="overflow-x-auto overflow-y-auto max-h-96 border border-gray-300 rounded-lg">
    //     <table className="table-auto border-collapse border border-gray-300 w-full text-sm text-gray-700">
    //       <thead>
    //         <tr>
    //           <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("block")}</th>
    //           <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("date")}</th>
    //           <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("subDepartment")}</th>
    //           <th className="border border-gray-300 p-3 bg-gray-200 text-center">{t("action")}</th>
    //         </tr>
    //       </thead>

    //       <tbody>
    //         {tableData.map((row, index) => {
    //           const rotation = currentRotation?.find((rotationItem) => rotationItem.date === row.date);
    //           const rowColor = rotation ? idToColorMap[rotation.sub_department_id] : "";

    //           return (
    //             <tr
    //               key={index}
    //               id={rotation ? rotation._id : ""}
    //               className={`border-b transition duration-300 ${rowColor ? rowColor : "hover:bg-gray-100"}`}
    //             >
    //               <td className="border border-gray-300 p-3 text-center">{row.block}</td>
    //               <td className="border border-gray-300 p-3 text-center">{row.date}</td>

    //               <td className="border border-gray-300 p-3">
    //                 <select
    //                   className="w-full h-10 border border-gray-300 rounded-lg px-3 focus:ring-2 focus:ring-darkBlue focus:outline-none"
    //                   name="subDepartment"
    //                   value={rotation ? rotation.sub_department_id : ""}
    //                   onChange={(e) => handleSelectChange(rotation ? rotation._id : "", index, e.target.value)}
    //                 >
    //                   <option value="" disabled={!rotation}>
    //                     {t("selectSubDepartment")}
    //                   </option>
    //                   {userDepartment.map((dept) => {
    //                     const isAlreadySelected = currentRotation.some((item) => item.sub_department_id === dept.value);
    //                     const remainingLimit = departmentLimits[dept.value] || 0;

    //                     return (
    //                       <option
    //                         key={dept.value}
    //                         value={dept.value}
    //                         className={isAlreadySelected ? "text-gray-400 font-semibold" : ""}
    //                         disabled={remainingLimit === 0}
    //                       >
    //                         {dept.label}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //               </td>

    //               <td className="p-3 flex flex-row justify-center items-center">
    //                 <button
    //                   className="w-8 h-8 flex items-center justify-center bg-red-500 text-white font-bold rounded-full transition duration-300 hover:bg-red-600 shadow-md"
    //                   onClick={() => handleDeleteOption(rotation ? rotation._id : "")}
    //                 >
    //                   ✖
    //                 </button>
    //               </td>
    //             </tr>
    //           );
    //         })}
    //       </tbody>

    //     </table>
    //   </div>
    // </div>
  );
};

export default ResponsiveRotationTable;
