import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../catalyst/table";
import fetchWithToken from "../../../utils/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Spinner from "../../spinner";
import TableFooter from "../../tableFooter";
import SwapRequestActionsModal from "../swapRequestActions";

interface OnCallData {
  _id: string;
  day: any;
  profileId: any;
  date: string;
  user_id: {
    _id: string;
    username: string;
    first_name: string;
    last_name: string;
    department?: {
      _id: string;
      name: string;
    };
  };
  shift_id?: {
    _id: string;
    shift_type?: string;
    shift_name?: string;
  };
}

interface OnCallsTableProps {
  activeTab: number;
  selectedOnCallsShift: any;
  filters: { departmentId: string; shiftId: string; shiftType: string; isNA: boolean };
}

const OnCallsTable: React.FC<OnCallsTableProps> = ({ activeTab, selectedOnCallsShift, filters }) => {
  const [onCallsData, setOnCallsData] = useState<OnCallData[]>([]);
  const [shifts, setShifts] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>({});
  const [selectedSecondData, setSelectedSecondData] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"));
  const { t } = useTranslation();
  const userRole = useSelector((state: any) => state?.user?.user?.role);
  const Myuser = useSelector((state: any) => state?.user?.user);
  const startDate = moment(currentMonth).startOf("month").format("YYYY-MM-DD");
  const endDate = moment(currentMonth).endOf("month").format("YYYY-MM-DD");

  const fetchShifts = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken("/shifts", { method: "GET" });
      setShifts(
        (data?.data || []).map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.shift_type || "Unknown Shift",
          startTime: each?.start_time || "00:00",
          endTime: each?.end_time || "00:00",
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch shifts:"), error);
    }
    setIsLoading(false);
  };

  const fetchOnCallsSchedules = async (firstDay: string, lastDay: string) => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(
        `/schedulesSearch?startDate=${firstDay}&endDate=${lastDay}`,
        { method: "GET" }
      );

      let filteredData = data?.data || [];

      if (filters.departmentId) {
        filteredData = filteredData.filter(
          (schedule: any) =>
            schedule.user_id?.department?._id === filters.departmentId
        );
      }

      if (filters.shiftId) {
        filteredData = filteredData.filter(
          (schedule: any) =>
            schedule.shift_id?._id === filters.shiftId
        );
      }

      if (filters.shiftType) {
        filteredData = filteredData.filter(
          (schedule: any) =>
            schedule.shift_id?.shift_type === filters.shiftType
        );
      }

      if (userRole === "manager" && Myuser?.department?.name) {
        filteredData = filteredData.filter(
          (schedule: any) =>
            schedule.user_id?.department?.name === Myuser.department.name
        );
      } else if (userRole === "user") {
        filteredData = filteredData.filter(
          (schedule: any) =>
            schedule.user_id?.first_name === Myuser.first_name &&
            schedule.user_id?.last_name === Myuser.last_name
        );
      }

      setOnCallsData(filteredData);
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchShifts();
  }, []);

  useEffect(() => {
    fetchOnCallsSchedules(startDate, endDate);
  }, [selectedOnCallsShift, currentMonth, filters]);

  const groupByDate = (data: OnCallData[]) => {
    return data.reduce((acc: Record<string, OnCallData[]>, item) => {
      const date = moment(item.date).format("YYYY-MM-DD");
      acc[date] = acc[date] || [];
      acc[date].push(item);
      return acc;
    }, {});
  };

  const sortedGroupedOnCalls = Object.entries(groupByDate(onCallsData))
    .sort(([a], [b]) => (moment(a).isBefore(b) ? -1 : 1))
    .reduce((acc: Record<string, OnCallData[]>, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const generateCalendar = (): Moment[] => {
    const startOfMonth = moment(currentMonth).startOf("month");
    const endOfMonth = moment(currentMonth).endOf("month");

    const daysInMonth: Moment[] = [];
    let currentDay = startOfMonth.clone();

    while (currentDay.isBefore(endOfMonth, 'day') || currentDay.isSame(endOfMonth, 'day')) {
      daysInMonth.push(currentDay.clone());
      currentDay.add(1, 'days');
    }

    return daysInMonth;
  };

  const daysInMonth = generateCalendar();

  const handlePrevMonth = () => {
    setCurrentMonth(moment(currentMonth).subtract(1, 'months').format("YYYY-MM"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(moment(currentMonth).add(1, 'months').format("YYYY-MM"));
  };
  const availablePermission = ({data}) => {
    const isAdmin = userRole === "manager" || userRole === "admin";
    if (isAdmin) return true;
  
    return data?.user_id?._id === Myuser?._id;
  };
  
  const handleShiftClick = (data) => {
    if (!availablePermission(data)) return;
    if(Object.keys(selectedData).length === 0){
      setSelectedData(data);
      setModalVisible(!isModalVisible);
    }else{
      setSelectedSecondData(data);
      setModalVisible(!isModalVisible);
    }
  };

  const emptyData = ()=>{
    setSelectedData({});
    setSelectedSecondData({})
  }


  return activeTab === 2 ? (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-4">
            <button onClick={handlePrevMonth}>{t("Previous Month")}</button>
            <div className="text-xl font-bold">
              {t(moment(currentMonth).format("MMMM"))} {moment(currentMonth).format("YYYY")}
            </div>
            <button onClick={handleNextMonth}>{t("Next Month")}</button>
          </div>
          <div className="">
            <Table className="w-full overflow-x-scroll border-none">
              <TableHead className="sticky top-0 z-3 bg-white shadow-md">
                <TableRow>
                  <TableHeader className="p-4 text-left text-[#3a4272] font-extrabold uppercase text-md border-none ">
                    <div className="bg-[#E6F4FF] rounded-3xl p-6 py-5 text-center">
                      {t("Date")}
                    </div>
                  </TableHeader>
                  {shifts.filter(Boolean).map((shift, index) => (
                    <TableHeader
                      key={index}
                      className="p-4 text-left text-[#3a4272] font-extrabold uppercase text-md border-none"
                    >
                      <div className="bg-[#E6F4FF] rounded-3xl p-2 mr-2 text-center">
                        <div className="text-lg underline">
                          {shift?.shift_type?.toUpperCase() || "UNKNOWN SHIFT"}
                        </div>
                        {shift?.shift_name && (
      <div className="text-md text-[#3a4272] font-semibold">
        {shift.shift_name}
      </div>
    )}
                        <div className="text-md font-bold">
                          {t("From")} <span className="text-gray-700">{shift?.startTime}</span> {t("To")} <span className="text-gray-700">{shift?.endTime}</span>
                        </div>
                      </div>
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {daysInMonth.map((day) => {
                  const dayKey = moment(day).format("YYYY-MM-DD");
                  const shiftsForDay = sortedGroupedOnCalls[dayKey] || [];

                  // const isAnyShiftAssigned = shifts.some(shift =>
                  //   shiftsForDay.some(shiftData => shiftData.shift_id?.shift_type === shift.shift_type)
                  // );
                  // if (!filters.isNA && !isAnyShiftAssigned) return null;
                  const isAnyShiftAssigned = shiftsForDay.length > 0;

                  if (!filters.isNA && !isAnyShiftAssigned) return null;


                  return (
                    <TableRow key={dayKey} className="group">
                      <TableCell className="rounded-3xl p-4 text-center italic font-semibold text-[#3a4272] border-none">
                        <div className="p-2 rounded-3xl border-none bg-[#F6FAFE]">
                          {moment(day).format("DD")}
                          <div className="text-sm text-[#3a4272] mt-1">
                            {t(moment(day).format("dddd"))}
                          </div>
                        </div>
                      </TableCell>

                      {shifts.map((shift, shiftIndex) => {
                        const shiftData = shiftsForDay.filter(
                          (shiftData: any) =>
                            shiftData.shift_id?.shift_type === shift.shift_type
                        );
                        return (
                          <TableCell key={shiftIndex} className="p-4 text-[#3a4272] border-none">
                            {shiftData.length > 0 ? (
                              <div
                                className="bg-[#E6F4FF] p-3 rounded-t-3xl shadow-md flex flex-col items-center justify-center group-hover:bg-[#A0D8FF]"
                              >
                                {shiftData.map((data, idx) => (
                                  <div
                                    title={t(`${data.user_id?.first_name} ${data.user_id?.last_name} assigned to ${shift.shift_type} on ${dayKey}`)}
                                    key={idx}
                                    onClick={() => handleShiftClick(data)}
                                    className={`text-sm text-center hover:bg-[#90c2e5] py-2 px-4 w-[150px] overflow-hidden whitespace-nowrap rounded-md ${data?._id === selectedData?._id && "bg-[#d9e6f0]"}`}
                                  >
                                    <span
                                      className={`inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap ${data.user_id?.first_name === Myuser?.first_name &&
                                        data.user_id?.last_name === Myuser?.last_name
                                        ? "text-red-300"
                                        : ""
                                        }`}
                                    >
                                      {data.user_id?.first_name || t("Unknown")}{" "}
                                      {data.user_id?.last_name || t("User")}
                                    </span>

                                    {data.user_id?.department && (
                                      <div className="text-xs text-gray-500  max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                        {data.user_id.department.name}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                title={t(`No one assigned to ${shift.shift_type} on ${dayKey}`)}
                                className="bg-[#E6F4FF] p-3 rounded-t-3xl shadow-md flex items-center justify-center group-hover:bg-[#A0D8FF]"
                              >
                                {t("N/A")}
                              </div>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

        </>
      )}
      <TableFooter />
      <SwapRequestActionsModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        selectedData={selectedData}
        selectedSecondData={selectedSecondData}
        emptyData={emptyData}
      />
    </>
  ) : null;
};

export default OnCallsTable;