import React, { useState, useEffect } from "react";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import Select from "react-select";

interface CancelModalProps {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  handleRefresh?: any;
}

const CancelModal: React.FC<CancelModalProps> = ({
  isModalVisible,
  setModalVisible,
  data,
  handleRefresh,
}) => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState(t("Title11"));
  const [animate, setAnimate] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    if (isModalVisible) {
      setButtonText(t("Title11"));
      setAnimate(false);
      setSelectedProfile(null);
    }
  }, [isModalVisible]);

  const handleAccept = async () => {
    try {
      const response = await fetchWithToken(`/swap-requests/${data?._id}/cancel`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response && handleRefresh) {
        handleRefresh();
      }

      setModalVisible(false);
      SuccessToast(
        t("Canceled"),
        t(`The Request Has Been Canceled`),
        t
      );
    } catch (error) {
      setModalVisible(false);
    }
  };

  return (
    <>
      {isModalVisible && (
        <main
          className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
          onClick={() => setModalVisible(false)}
        >
          <div
            className="py-2 xl:w-[30%] w-[70%] h-[20%] z-[9999] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-3 text-center text-lg font-bold">
              {t("Cancel Request")}
            </div>
            <div className="py-3 text-center text-md">
              {t("Are You Sure You Want To Cancel Request ?")}
            </div>

            <div className="flex justify-end w-[90%] mt-3">
              <button
                onClick={() => setModalVisible(false)}
                className="px-4 py-2 border border-green-500 text-green-500 mr-2 rounded"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleAccept}
                className={`w-[174px] rounded text-white text-lg px-6 py-2 mr-2 ${animate
                  ? "animate-success"
                  : "bg-green-600 hover:bg-green-700"
                  }`}
              >
                {"Cancel Request"}
              </button>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default CancelModal;
