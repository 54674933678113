import React, { useEffect, useState } from "react";
import darkclose from "../../assets/darkClose.png";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useSelector } from "react-redux";
import Select from "react-select";

const SwapRequestActionsModal: any = ({
  isModalVisible,
  setModalVisible,
  selectedData,
  selectedSecondData,
  emptyData
}) => {
  const { t } = useTranslation();
  const userDepartment = useSelector((state: any) => state.user.user.department._id)
  const [giveShiftToCollegue, setGiveShiftToCollegue] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const [profiles, setProfiles] = useState<any[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState("option1");
  const options = [
    { id: "anyone", label: "Give Shift To Anyone" },
    { id: "colleague", label: "Give Shift To Colleague" },
    { id: "swap", label: "Swap Shift" },
  ];


  const fetchProfiles = async () => {
    try {
      const data = await fetchWithToken(`/users?department=${userDepartment}`, { method: "GET" });
      setProfiles(data?.data?.map((user: any) => ({ ...user, value: user._id, label: user.email })));
    } catch (error) {
      console.error(t("Failed to fetch profiles:"), error);
    }
  };

  const createNewSwapRequest = async ({ firstId, secondId }) => {
    try {
      const response = await fetchWithToken("/swap-requests", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          secondId
            ? {
              from_schedule_id: firstId,
              to_schedule_id: secondId,
            }
            : {
              from_schedule_id: firstId,
            }
        ),
      });

      setModalVisible(!isModalVisible);

      if (!response?.message) {
        SuccessToast(t("Created"), t("swap request created successfully"), t);
      } else {
        FailToast(response?.message, t);
      }
    } catch (error) {
      console.error(t("ErrorLog"), error);
      setModalVisible(!isModalVisible);
    }
  };

  const createNewSwapRequestWithUser = async ({ firstId }) => {
    try {
      const response = await fetchWithToken("/user-swap-requests", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            from_schedule_id: firstId,
            to_user_id: selectedProfile?._id
          }
        ),
      });

      setModalVisible(!isModalVisible);

      if (!response?.message) {
        SuccessToast(t("Created"), t("swap request created successfully"), t);
      } else {
        FailToast(response?.message, t);
      }
    } catch (error) {
      console.error(t("ErrorLog"), error);
      setModalVisible(!isModalVisible);
    }
  }

  const handleChoose = () => {
    setProfileError(false)
    if (Object.keys(selectedSecondData).length > 0) {
      createNewSwapRequest({
        firstId: selectedData?._id,
        secondId: selectedSecondData?._id
      })
      emptyData()
    }
    if (selectedOption !== "colleague") {
      setSelectedProfile("")
    }
    switch (selectedOption) {
      case "anyone":
        createNewSwapRequest({
          firstId: selectedData?._id,
          secondId: null
        });
        emptyData()
        break;
      case "colleague":
        if (!selectedProfile) {
          setProfileError(true)
          return
        }
        createNewSwapRequestWithUser({
          firstId: selectedData?._id,
        })
        emptyData()
        break;
      case "swap":
        setModalVisible(false);
        // handleOpenNewSwapRequest()
        break;
      default:
        break;
    }
  }

  const handleCancel = () => {
    setModalVisible(false)
    emptyData()
  }

  const handleUserChange = (chosenData) => {
    setSelectedProfile(chosenData)
    setProfileError(false)
  }

  useEffect(() => {
    if (userDepartment) {
      fetchProfiles()
      setSelectedProfile("")
    }
  }, [userDepartment])


  return (
    isModalVisible && (
      <main className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center">
        <div className="py-5 w-[700px] h-auto relative m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col shadow-[1px_2px_6px_0px_#282C2B14]">
          {/* Header */}
          <div className="flex items-center justify-center mb-2 relative">
            <div className="py-1 text-center text-xl font-bold">
              {t("ShiftDetails")}
            </div>
            <div
              className="cursor-pointer absolute right-2"
              onClick={() => setModalVisible(false)}
            >
              <img src={darkclose} alt="close" />
            </div>
          </div>

          {/* Content Area */}
          <div className="flex-grow overflow-auto px-6 text-sm text-[#101010]">
            <div className="pt-2 text-lg text-[#161918]">
              For Shift{" "}
              <span className="font-bold">
                {selectedData?.shift_id?.shift_type || "N/A"}
              </span>{" "}
              owned by user{" "}
              <span className="font-bold">
                {selectedData?.user_id?.first_name || "Unknown"}{" "}
                {selectedData?.user_id?.last_name || "User"}
              </span>
            </div>

            {Object.keys(selectedSecondData).length === 0 && (
              <div className="w-full m-auto mt-2 max-w-2xl border border-gray-300 rounded-[16px] p-4 flex justify-between">
                {/* Radio Buttons (Left Aligned) */}
                <div>
                  {options.map(({ id, label }) => (
                    <div key={id} className="flex items-center mb-2 last:mb-0">
                      <input
                        type="radio"
                        id={id}
                        name="switch"
                        value={id}
                        checked={selectedOption === id}
                        onChange={() => setSelectedOption(id)}
                        className="h-4 w-4 text-blue-500 border-gray-300 focus:ring-blue-500 cursor-pointer"
                      />
                      <label
                        htmlFor={id}
                        className="ml-2 text-gray-700 cursor-pointer"
                      >
                        {label}
                      </label>
                    </div>
                  ))}
                </div>

                {/* Text on the Right Side */}
                {selectedOption === "colleague" && (
                  <div className="z-50">
                    <Select
                      placeholder={t("Select")}
                      options={profiles}
                      value={selectedProfile}
                      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                      getOptionValue={(option) => option._id}
                      onChange={(selected) => handleUserChange(selected)}
                      className="w-[250px]"
                    />
                  </div>
                )}
              </div>
            )}

            {Object.keys(selectedSecondData).length > 0 && (
              <div className="flex items-center justify-center my-4">
                <div className="bg-white border border-gray-300 rounded-[16px] p-4 w-[250px] shadow-sm">
                  <h3 className="text-center font-semibold mb-2">Current Shift</h3>
                  <ul className="text-gray-600 text-sm space-y-1">
                    <li>Start Time: {selectedData?.shift_id?.start_time}</li>
                    <li>End Time: {selectedData?.shift_id?.end_time}</li>
                    <li>Department: {selectedData?.department_id?.name}</li>
                  </ul>
                </div>

                <div className="mx-4 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                  </svg>
                </div>

                <div className="bg-white border border-gray-300 rounded-[16px] p-4 w-[250px] shadow-sm">
                  <h3 className="text-center font-semibold mb-2">New Shift</h3>
                  <ul className="text-gray-600 text-sm space-y-1">
                    <li>Start Time: {selectedSecondData?.shift_id?.start_time}</li>
                    <li>End Time: {selectedSecondData?.shift_id?.end_time}</li>
                    <li>Department: {selectedSecondData?.department_id?.name}</li>
                  </ul>
                </div>
              </div>
            )}

          </div>
          {profileError && (

            <div className="text-[#FF0000] ml-7 mt-4">
              Please Choose User To Swap With
            </div>
          )}
          <div className="flex justify-around w-full py-4 mt-5 border-t border-gray-200">
            <button
              onClick={handleCancel}
              type="button"
              className="w-[120px] h-[40px] rounded-md border border-[#FF0000] text-[#FF0000] hover:bg-[#FF0000] hover:text-white transition-colors duration-200 text-[16px] font-semibold"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleChoose}
              type="button"
              className="w-[120px] h-[40px] rounded-md border border-[#00A843] text-[#389365] hover:bg-[#00A843] hover:text-white transition-colors duration-200 text-[16px] font-semibold"
            >
              {t("Submit")}
            </button>
          </div>

        </div>
      </main >
    )
  );

};

export default SwapRequestActionsModal;
