import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import Select from "react-select";

interface LeaveProps {
  isModalVisible: any;
  setIsModalVisible: any;
  fetchLeaves: any
}

const LeaveTypes: React.FC<LeaveProps> = ({ isModalVisible, setIsModalVisible, fetchLeaves }) => {
  const isAdd = isModalVisible === true;
  const [leavesTypes, setLeavesTypes] = useState<any>([]);
  const { t, i18n } = useTranslation();
  // const [options,setOptions] =useState()

  const formik = useFormik({
    initialValues: {
      type: "",
      allowed_count: "",
      note: "",
    },

    validationSchema: Yup.object({
      type: Yup.string().required(t("Required")),
      allowed_count: Yup.number()
        .typeError(t("Must be a number"))
        .required(t("Required")),
      note: Yup.string().required(t("Required")),
    }),

    onSubmit: async (values) => {
      try {
        await fetchWithToken(
          isAdd ? `/leaves/types` : `/leaves/types/${isModalVisible}`,
          {
            method: isAdd ? "POST" : "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: values.type,
              allowed_count: values.allowed_count,
              note: values.note
            }),
          }
        );
        fetchLeaves();
        setIsModalVisible(false);
      } catch (error) {
        console.error(t("Error creating leave:"), error);
        setIsModalVisible(false);
      }
    },
  });

  const getLeaveDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/leaves/types/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("type", data?.type);
      formik?.setFieldValue("allowed_count", data?.allowed_count);
      formik?.setFieldValue("note", data?.note);

    } catch (error) {
      console.error("Failed to fetch department:", error);
    }
  };

  const fetchLeavesTypes = async () => {
    try {
      const data = await fetchWithToken("/leaves/types", {
        method: "GET",
      });
      setLeavesTypes(
        data.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.type,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getLeaveDetails(isModalVisible);
    fetchLeavesTypes()
  }, [isModalVisible]);


  const formatShiftLabelUser = (props) => {
    return (
      <div className="flex flex-col">
        <div>
          {props?.type}
        </div>
        <div>
        {t("Allowed Count is")} {props?.allowed_count}
        </div>
      </div>
    );
  };

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[999]"
        onClick={() => setIsModalVisible(!isModalVisible)}
      >
        <div
          className="py-5  overflow-auto
           m-auto  capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center justify-center  scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">{t("Mesaage")}</div>
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("Type")}</div>
            <input
              type="text"
              placeholder={t("Type")}
              name="type"
              required
              className="mt-1 mb-3 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.type}
              style={{
                borderColor:
                  formik?.touched?.type && formik?.errors?.type
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            {/* <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.first_name && formik?.errors?.first_name && (
                <div>{formik?.errors?.first_name}</div>
              )}
            </div> */}
            <div className="font-bold">{t("Allowed Count")}</div>
            <input
              type="text" // Change to text to fully control input
              placeholder={t("EndDateTitle")}
              name="allowed_count"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="allowed_count"
              inputMode="numeric" // Ensures only numbers can be typed (mobile-friendly)
              pattern="[0-9]*" // Ensures only digits (avoids non-numeric input)
              onChange={(e) => {
                let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                if (value.length > 2) value = value.slice(0, 2); // Limit to 2 characters
                formik.setFieldValue("allowed_count", value);
              }}
              onBlur={formik?.handleBlur}
              value={formik?.values?.allowed_count}
              style={{
                borderColor:
                  formik?.touched?.allowed_count && formik?.errors?.allowed_count
                    ? "#E23121"
                    : "#5D6561",
              }}
            />

            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.allowed_count && formik?.errors?.allowed_count && (
                <div>{formik?.errors?.allowed_count}</div>
              )}
            </div>
            <div className="font-bold">{t("Note")}</div>
            <textarea
              placeholder={t("Note")}
              name="note"
              required
              className="mt-1 mb-3 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.note}
              rows={4}
              style={{
                borderColor:
                  formik?.touched?.note && formik?.errors?.note ? "#E23121" : "#5D6561",
                resize: "none",
              }}
            ></textarea>
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.note && formik?.errors?.note && (
                <div>{typeof formik?.errors?.note === "string" && formik?.errors?.note}</div>
              )}
            </div>

          </div>
          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setIsModalVisible(false)}
              className="w-[168px] h-[40px] rounded-[8px] border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue text-[16px] font-[700] px-[24px] py-[8px]"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              type="submit"
              className="w-[168px] rounded-[8px]  bg-[darkblue] hover:bg-[#1A275E] text-white p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default LeaveTypes;
