import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FullCalendarComponent, { CalendarEvent as BaseCalendarEvent } from "../full-calendar/fullCalendar";
import fetchWithToken from "../../utils/api";
import moment from "moment";
import { dateToFormatedTime } from "../../utils/functions";
import { useTranslation } from "react-i18next";

interface CalendarEvent extends BaseCalendarEvent {
  extendedProps?: {
    rotation: string;
    iconHtml?: string;
  };
}

interface SubDepartment {
  _id: string;
  name: string;
}

interface Department {
  _id: string;
  name: string;
  sub_departments: SubDepartment[];
}

interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift_id: string;
  user_id: string;
  department?: { name: string };
  sub_department?: {
    _id: string;
    name: string;
  };
  shift?: {
    is_rotation: boolean;
    id: number;
    start_time: string;
    end_time: string;
    shift_type: string;
    createdAt: string;
    date: string;
    shift_name: string;
  };
  rotation?: string;
}

function NewCalendarDesign() {
  const [shifts, setShifts] = useState<CalendarEvent[]>([]);
  const [isFetchingSchedules, setIsFetchingSchedules] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const user = useSelector((state: any) => state.user.user);
  const [activeDepartment, setActiveDepartment] = useState<Department | null>(null);
  const [activeSubDepartment, setActiveSubDepartment] = useState<string | null>(null);
  const [originalSchedules, setOriginalSchedules] = useState<any>();
  const [schedule, setSchedule] = useState<any>();
  const { t } = useTranslation();

  const userDepartment = useSelector((state: any) => state.user.user.department);

  useEffect(() => {
    if (user?._id) {
      fetchSchedules();
      fetchScheduleSum();
    }
  }, [user?._id, currentDate]);

  const fetchScheduleSum = async () => {
    if (!user?._id) return;
    
    try {
      const query = `/schedules-rules/rotations/department?department_id=${
        userDepartment?._id
      }&limit=300${
        activeSubDepartment ? `&sub_department_id=${activeSubDepartment}` : ""
      }`;

      const data = await fetchWithToken(query, { method: "GET" });
    
      if (!data?.data) {
        console.warn("No schedules found.");
        return;
      }

      const filteredSchedules = data.data.filter(
        (schedule: any) => schedule?.user?._id === user?._id
      );
      setOriginalSchedules(filteredSchedules);
      setSchedule(filteredSchedules);
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
    }
  };

  const fetchSchedules = async () => {
    const startDate = currentDate.clone().startOf("month").format("YYYY-MM-DD");
    const endDate = currentDate.clone().add(1, "month").endOf("month").format("YYYY-MM-DD");

    setIsFetchingSchedules(true);
    try {
      const data = await fetchWithToken(`/schedules?startDate=${startDate}&endDate=${endDate}&department_id=${
        activeDepartment?._id || userDepartment?._id
      }`, {
        method: "GET",
      });

      if (!data?.data || !Array.isArray(data.data)) {
        console.error("Invalid API response structure", data); 
        return;
      }

      const userSchedules = data.data.filter(
        (schedule: Schedule) =>
          schedule.user_id && schedule.shift && String(schedule.user_id) === String(user._id)
      );

      setShifts(mapShiftsToEvents(userSchedules));
    } catch (error) {
      console.error("Fetch Error in fetchSchedules:", error);
    } finally {
      setIsFetchingSchedules(false);
    }
  };

  const mapShiftsToEvents = (schedules: Schedule[]): CalendarEvent[] => {
    if (!schedules.length) return [];

    return schedules.map((scheduleItem) => {
      if (!scheduleItem.shift) return null;
      // if (scheduleItem.shift.is_rotation) {
      //   console.log("Rotation Schedule Found:", {
      //     date: scheduleItem.date,
      //     shift: scheduleItem.shift.shift_name,
      //     subDepartment: scheduleItem.sub_department?.name || "Unknown Sub-Dept",
      //     fullData: scheduleItem
      //   });
      // } to make sure sub dept if showing or no menon eh w menon no so mechiye tabi3e
      const rawDate = moment(scheduleItem.date).format("YYYY-MM-DD");
      const startTime = scheduleItem.shift.start_time;
      const endTime = scheduleItem.shift.end_time;

      let endDate = rawDate;
      if (moment(endTime, "HH:mm").isBefore(moment(startTime, "HH:mm"))) {
        endDate = moment(rawDate).add(1, "day").format("YYYY-MM-DD");
      }

      const isRotation = scheduleItem.shift.is_rotation;
      const subDepartmentName = scheduleItem.sub_department?.name || "";
      const displayText = isRotation 
        ? ` (${subDepartmentName})`
        : ` (${scheduleItem.shift.shift_type} - ${scheduleItem.shift.shift_name})`;

      const startDateTime = moment(`${rawDate}T${startTime}`).toISOString();
      const endDateTime = moment(`${endDate}T${endTime}`).toISOString();

      const event: CalendarEvent = {
        id: `event-${scheduleItem.shift.id}-${uuidv4()}`,
        start: startDateTime,
        end: endDateTime,
        title: `${dateToFormatedTime(startTime, rawDate)} - ${dateToFormatedTime(
          endTime,
          endDate
        )}${displayText}`,
        classNames: isRotation ? "bg-[#6B3F2A] text-white" : "bg-blue-300",
        extendedProps: {
          rotation: isRotation ? "Rotation Shift" : "Non-Rotation Shift",
          iconHtml: "",
        },
      };

      return event;
    }).filter((event): event is CalendarEvent => event !== null);
  };

  return (
    <FullCalendarComponent
      calendarEvents={shifts}
      nextMonthHandler={() => setCurrentDate(currentDate.clone().add(1, "month"))}
      lastMonthHandler={() => setCurrentDate(currentDate.clone().subtract(1, "month"))}
      dateClickHandler={(obj: any) => console.log("Date Clicked:", obj)}
    />
  );
}

export default NewCalendarDesign;