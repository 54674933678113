import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import editIcon from "../../assets/cruds/editc.svg";
import ViewIcon from "../../assets/cruds/view.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import DeleteModal from "../modals/deleteModal";
import Shift from "../modals/shift";
import moment from "moment";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner";
import RotationExport from "../modals/RotationExport";

interface Shifts {
  is_rotation: boolean;
  _id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
  shift_name: string;
}

interface ShiftProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Shifts: React.FC<any> = ({ isModalVisible, setModalVisible, setIsRotationExport,
  isRotationExport, }) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [shifts, setShifts] = useState<Shifts[]>([]);
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchShifts = async () => {
    setIsLoading(true)
    try {
      const data = await fetchWithToken(`/shifts?page=${currentPage}`, {
        method: "GET",
      });
      console.log("shifts", data);
      setShifts(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit)
      setIsLoading(false)
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
      setIsLoading(false)
    }
  };
  useEffect(() => {
    fetchShifts();
  }, [currentPage, count]);

  return (
    <>
      {isLoading ? <Spinner /> : <>
        <Shift
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          fetchShifts={fetchShifts}
        />
        <DeleteModal
          route="shifts"
          visibilityState={deleteRequestModal}
          setState={setDeleteRequestModal}
          fetchAllCall={fetchShifts}
        />
        <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
          <TableHead className="hidden lg:table-header-group w-full text-center">
            <TableRow className="bg-white w-full text-center">
              <TableHeader className="!outline-none !border-b-0 ">
                <div className="flex items-center justify-center text-black ">
                  {t("Number")}
                </div>
              </TableHeader>
              <TableHeader className="!outline-none !border-b-0 text-black">
                <div className="flex items-center justify-center">
                  {t("ShiftType")}
                </div>
              </TableHeader>
              <TableHeader className="!outline-none !border-b-0 text-black">
  <div className="flex items-center justify-center">
    {t("Shift Name")}
  </div>
</TableHeader>

              <TableHeader className="!outline-none !border-b-0 text-black">
                <div className="flex items-center justify-center">
                  {t("StartTime")}
                </div>
              </TableHeader>
              <TableHeader className="!outline-none !border-b-0 text-black">
                <div className="flex items-center justify-center">
                  {t("EndTime")}

                </div>
              </TableHeader>

              <TableHeader className="!outline-none !border-b-0 text-black"></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="w-full text-center">
            {Array.isArray(shifts) &&
              shifts?.map((shift, index) => {
                const { _id, start_time, end_time, shift_type } = shift;
                const rowNumber = index + 1 + (currentPage - 1) * count;
                return (
                  <TableRow
                    key={_id}
                    className="cursor-pointer hover:bg-slate-100 lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
                  >
                    <TableCell className="lg:hidden font-bold bg-gray-100">
                      {t("Number")}
                    </TableCell>
                    <TableCell>{rowNumber}</TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100">
                      {t("ShiftType")}
                    </TableCell>
                    <TableCell >
                      {shift_type}
                    </TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100">
  {t("Shift Name")}
</TableCell>
<TableCell>
  {shift.shift_name}
</TableCell>

                    <TableCell
                      className="lg:hidden font-bold bg-gray-100"
                    >
                      {t("StartTime")}
                    </TableCell>
                    <TableCell >
                      {moment(start_time, "HH:mm:ss.SSS").format("h:mm:ss A")}
                    </TableCell>

                    <TableCell className="lg:hidden font-bold bg-gray-100">
                      {t("EndTime")}
                    </TableCell>
                    <TableCell >
                      {moment(end_time, "HH:mm:ss.SSS").format("h:mm:ss A")}
                    </TableCell>

                    

                    <TableCell className="flex justify-end w-full">
                      {
                        shift?.is_rotation === true && (
                          <div
                            onClick={() => navigate(`/shift/${_id}`)}
                            className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                          >
                            <img alt="ViewIcon" src={ViewIcon} className="w-5 h-5 mt-1" />
                          </div>

                        )
                      }
                      <div
                        onClick={() => setModalVisible(_id)}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                      </div>

                      <div
                        onClick={() => setDeleteRequestModal(_id)}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img
                          alt="deleteIcon"
                          src={deleteIcon}
                          className="w-6 h-6"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </>}
      <RotationExport
        isRotationExport={isRotationExport}
        setRotationExport={setIsRotationExport}
      />
    </>
  );
};

export default Shifts;
