import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import fetchWithToken from "../../utils/api";
import SuccessToast from "../../components/modals/successToast";
import FailToast from "../../components/modals/failToast";
import DeleteOnCallsModal from "../../components/modals/deleteOnCallsModal";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import leftarrowsideIcon from "../../assets/left_arrow_F.svg";
import Spinner from "../../components/spinner";

type OnCallData = {
    _id: string;
    scheduleId: string;
    day: string;
    profileId: any;
    date: string;
    user_id: {
        _id: string;
        username: string;
        first_name: string;
        last_name: string;
        department: {
            _id: string;
            name: string;
        };
        position: {
            _id: string;
            name: string;
            levels: string[];
        };
        level: string;
        contact: string;
        work_contact: string;
    };
};

type SelectedDay = {
    day: string;
    profileId: any;
    date: string;
};


const OnCallsCreation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [shifts, setShifts] = useState<any[]>([]);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<any>(moment());
    const [numberOfWeeks, setNumberOfWeeks] = useState(1);
    const [currentWeek, setCurrentWeek] = useState(1);
    const [selectedShift, setSelectedShift] = useState<any>();
    const [onCallsData, setOnCallsData] = useState<OnCallData[]>([]);
    const [initialOnCallsData, setInitialOnCallsData] = useState<OnCallData[]>([]);
    const [currentWeekDate, setCurrentWeekDate] = useState(moment());
    const [selectedDays, setSelectedDays] = useState<SelectedDay[]>([]);
    const [selectedDaysForDelete, setSelectedDaysForDelete] = useState<any>();
    const [deleteRequestModal, setDeleteRequestModal] = useState<
        boolean | string | number
    >(false);

    const daysOfWeek = [
        { value: "0", label: t("Sat") },
        { value: "1", label: t("Sun") },
        { value: "2", label: t("Mon") },
        { value: "3", label: t("Tue") },
        { value: "4", label: t("Wed") },
        { value: "5", label: t("Thu") },
        { value: "6", label: t("Fri") },
    ];

    const storeLatestState = () => {
        return {
            selectedShift,
            numberOfWeeks,
            selectedDays,
            currentWeek,
            startDate,
        };
    };

    const weeksOptions = Array.from({ length: 8 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
    }));

    const refreshData = async () => {
        const latestState = storeLatestState();
        console.log("last state is ", JSON.stringify(storeLatestState()))
        // Calculate the first and last day of the current selected week
        const firstDay = latestState?.startDate.clone().startOf('week').subtract(1, 'days').format("YYYY-MM-DD");
        const lastDay = latestState?.startDate.clone().endOf('week').subtract(1, 'days').format("YYYY-MM-DD");
        console.log("first day is ", JSON.stringify(firstDay))
        console.log("last day is ", JSON.stringify(lastDay))
        // Fetch the updated on-call schedules for the selected week
        setLoading(true)
        await fetchOnCallsSchedules(firstDay, lastDay);

        // Reinitialize selected days for the current number of weeks
        initializeSelectedDaysForWeeks(latestState.numberOfWeeks);
    };



    const fetchProfiles = async (id) => {
        try {
            const data = await fetchWithToken(`/users?department=${id}`, { method: "GET" });
            setProfiles(data?.data?.map((user: any) => ({ ...user, value: user._id, label: user.email })));
        } catch (error) {
            console.error(t("Failed to fetch profiles:"), error);
        }
    };

    const fetchShifts = async () => {
        try {
            const data = await fetchWithToken("/shifts", { method: "GET" });
            const formattedShifts = data?.data?.map((shift: any) => ({
                ...shift,
                value: shift._id,
                label: shift.shift_type,
            }));
            setShifts(formattedShifts);
        } catch (error) {
            console.error(t("Failed to fetch shifts:"), error);
        }
    };

    const fetchOnCallsSchedules = async (firstDay: string, lastDay: string) => {
        if (!selectedShift) return;
        try {
            const data = await fetchWithToken(
                `/schedulesSearch?startDate=${firstDay}&endDate=${lastDay}&shift_id=${selectedShift._id}`,
                { method: "GET" }
            );

            setOnCallsData(data?.data);
        } catch (error) {
            console.error("Failed to fetch schedules:", error);
        }
    };



    const getFormattedDate = (dayLabel: string) => {
        const baseDate = startDate ? moment(startDate, "YYYY-MM-DD") : moment();
        if (!baseDate.isValid()) return "Invalid Date";
        const dayOfWeekIndex = daysOfWeek.find((d) => d.label === dayLabel)?.value;
        if (dayOfWeekIndex === undefined) return "Invalid Day";
        const startOfWeek = baseDate.clone().startOf("week").subtract(1, "days");
        const selectedDate = startOfWeek.clone().add(dayOfWeekIndex, "days");
        const finalDate = selectedDate.add((currentWeek - 1) * 7, "days");
        return finalDate.format("DD-MM-YYYY");
    };


    const toggleDay = (day: { value: string; name: string }) => {
        // console.log("toggleDay worked");
        // console.log("day",day);
        // console.log("onCallsData",onCallsData)
        // console.log("onCallsData",JSON.stringify(onCallsData))
        setSelectedDays((prev) => {
            const existingDay = prev.find((d) => d.day === day.name);

            if (existingDay) {
                return prev.filter((d) => d.day !== day.name);
            } else {
                const baseDate = startDate ? moment(startDate, "YYYY-MM-DD") : moment();

                if (!baseDate.isValid()) {
                    console.error("❌ Invalid startDate:", startDate);
                    return prev;
                }

                const dayOfWeekIndex = daysOfWeek.find((d) => d.label === day.name)?.value;
                const selectedDate = baseDate.clone().startOf("week").add(dayOfWeekIndex, "days").subtract(1, "day");
                const formattedDate = selectedDate.format("YYYY-MM-DD");

                // Filter to get all matching data instead of just one
                const matchingData = onCallsData.filter((data) =>
                    moment(data.date).format("YYYY-MM-DD") === formattedDate
                );

                // Extract all profileIds from the matching data
                const profileIds = matchingData.map((data) => data.user_id);

                return [
                    ...prev,
                    {
                        day: day.name,
                        profileId: profileIds.length > 0 ? profileIds : null,
                        date: formattedDate,
                        scheduleId: matchingData.map((data) => data._id)
                    }
                ];
            }
        });
    };


    const saveCall = async ({ date, userId, shiftId }: { date: string; userId: string; shiftId: string }) => {
        console.log("date", date)
        console.log("userId", userId)
        console.log("shiftId", shiftId)
        try {
            const response = await fetchWithToken(
                "/schedules",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ date, user_id: userId, shift_id: shiftId }),
                }
            );

            const newSchedule = await response?.data;
            setRefresh((prev) => !prev)
            SuccessToast(t("Saved"), t(`Schedule Saved Successfully`), t);

            // // Refresh the data for the correct week after saving
            // await refreshData();

            // Store the latest state
            storeLatestState();

        } catch (error) {
            console.error("❌ Unexpected error:", error);
            FailToast(t("There is an overlapping schedule"), t);
        }
    };

    const DeleteCall = async () => {
        try {
            await fetchWithToken(
                `/schedules/${(selectedDaysForDelete as any)}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            // // Remove the day from the selected days
            // if (selectDayIndexForDelete !== undefined) {
            //     setSelectedDays((prev) => prev.filter((_, index) => index !== selectDayIndexForDelete));
            // }

            // Refresh data for the correct week after deleting
            // await refreshData();

            SuccessToast(t("Saved"), t(`Schedule Deleted Successfully`), t);
            setRefresh((prev) => !prev)

            // // Remove the deleted day from selectedDays if needed
            // if (selectDayIndexForDelete !== undefined) {
            //     removeSelectedDay(selectDayIndexForDelete);
            // }

        } catch (error) {
            console.error("❌ Unexpected error:", error);
            FailToast(t("Failed to delete schedule"), t);
        }
    };


    const handleProfileChange = (dayIndex: number, option: any, value: any) => {
        let newValue
        const updatedDays = [...selectedDays];
        updatedDays[dayIndex].profileId = option;

        const baseDate = startDate ? moment(startDate, "YYYY-MM-DD") : moment();
        if (!baseDate.isValid()) {
            console.error("❌ Invalid startDate:", startDate);
            return updatedDays;
        }

        const selectedDay = updatedDays[dayIndex].day;
        const dayOfWeekIndex = parseInt(daysOfWeek.find((d) => d.label === selectedDay)?.value ?? "-1");
        if (isNaN(dayOfWeekIndex) || dayOfWeekIndex < 0 || dayOfWeekIndex > 6) {
            console.error("❌ Invalid day index:", selectedDay);
            return updatedDays;
        }

        const selectedDate = baseDate.clone().startOf("week").add(6, "days").add(dayOfWeekIndex, "days");
        const finalDate = selectedDate.add((currentWeek - 2) * 7, "days");
        if (!finalDate.isValid()) {
            console.error("❌ Final date calculation failed.");
            return updatedDays;
        }

        if (option.length > value.length) {
            // Added New User
            console.log("Added New User")
            newValue = option.filter(newItem =>
                !value.some(oldItem => oldItem._id === newItem._id)
            );
            setSelectedDays(updatedDays)
            saveCall({ userId: newValue[0]._id, date: finalDate.format("YYYY-MM-DD"), shiftId: selectedShift._id })
        } else {
            // Removed User
            console.log("Removed User")
            newValue = value.filter(newItem =>
                !option.some(oldItem => oldItem._id === newItem._id)
            );
            setSelectedDays(updatedDays)
            getScheduleIdFromOnCallsData(newValue[0]._id, finalDate.format("YYYY-MM-DD"))
        }


    };

    const getScheduleIdFromOnCallsData = (userId, date) => {
        console.log("getScheduleIdFromOnCallsData useId", userId);
        console.log("getScheduleIdFromOnCallsData date", date);

        const filteredData = onCallsData.find(
            (item) => item.date.startsWith(date) && item.user_id._id === userId
        );

        console.log("Filtered Data:", filteredData?._id);
        setDeleteRequestModal((prev) => !prev)
        setSelectedDaysForDelete(filteredData?._id)
    };



    const removeSelectedDay = (dayIndex: number) => {
        setSelectedDays((prev) => prev.filter((_, index) => index !== dayIndex));

    };

    const initializeSelectedDaysForWeeks = (weeksCount: number) => {
        const baseDate = startDate ? moment(startDate, "YYYY-MM-DD") : moment();
        if (!baseDate.isValid()) {
            console.error("❌ Invalid startDate:", startDate);
            return;
        }
        const selectedDays: any[] = [];

        for (let i = 0; i < weeksCount; i++) {
            const startOfWeek = baseDate.clone().startOf("week").add(i, "weeks");

            for (let j = 0; j < 7; j++) {
                const selectedDate = startOfWeek.clone().add(j, "days").subtract(1, "day");
                const formattedDate = selectedDate.format("YYYY-MM-DD");
                // Find all matching schedules for the given date
                const matchingData = onCallsData.filter(
                    (data) => moment(data.date).format("YYYY-MM-DD") === formattedDate
                );

                let profileId;
                let scheduleIds;

                if (matchingData.length > 1) {
                    // If multiple schedules exist, store profileId as an array
                    profileId = matchingData.map((data) => data.user_id);
                    scheduleIds = matchingData.map((data) => data._id);
                } else if (matchingData.length === 1) {
                    // If only one schedule exists, store it as a single value
                    profileId = matchingData[0].user_id;
                    scheduleIds = matchingData[0]._id;
                } else {
                    // If no schedules exist, set profileId to null
                    profileId = null;
                    scheduleIds = null;
                }

                selectedDays.push({
                    day: selectedDate.format("dddd"),
                    profileId,
                    date: formattedDate,
                    scheduleId: scheduleIds,
                });
            }
        }

        console.log("📝 Finalized initialOnCallsData:", JSON.stringify(selectedDays));
        setInitialOnCallsData(selectedDays);
        setLoading(false)
    };



    const handleNumberOfWeeksChange = (selectedOption: any) => {
        setNumberOfWeeks(selectedOption.value);
    };

    const handleNextWeek = () => {
        if (currentWeek < numberOfWeeks) {
            const nextWeek = currentWeekDate.clone().add(1, "week");
            setCurrentWeekDate(nextWeek);
            setCurrentWeek(currentWeek + 1);
            updateSelectedDays();
        }
    };

    const handleBackWeek = () => {
        if (currentWeek > 1) {
            const prevWeek = currentWeekDate.clone().subtract(1, "week");
            setCurrentWeekDate(prevWeek);
            setCurrentWeek(currentWeek - 1);
            updateSelectedDays();
        }
    };

    const updateSelectedDays = () => {
        console.log("toggleDay updated");
        setSelectedDays((prev) => {
            return prev.map((selectedDay) => {
                const baseDate = startDate ? moment(startDate, "YYYY-MM-DD") : moment();
                if (!baseDate.isValid()) {
                    console.error("❌ Invalid startDate:", startDate);
                    return selectedDay;
                }
                const dayOfWeekIndex = daysOfWeek.find((d) => d.label === selectedDay.day)?.value;
                if (dayOfWeekIndex === undefined) return selectedDay;
                const selectedDate = baseDate.clone().startOf("week").add(dayOfWeekIndex, "days").subtract((currentWeek - 2) * 7, "days").subtract(1, "days");
                const formattedDate = selectedDate.format("YYYY-MM-DD");
                const matchingData = initialOnCallsData.find((data) => moment(data.date).format("YYYY-MM-DD") === formattedDate);
                const profileId = matchingData ? matchingData.profileId : null;
                return { ...selectedDay, date: formattedDate, profileId };
            });
        });
    };
    const getOrdinalSuffix = (index: number) => {
        const mod10 = index % 10;
        const mod100 = index % 100;
        if (mod10 === 1 && mod100 !== 11) return "st";
        if (mod10 === 2 && mod100 !== 12) return "nd";
        if (mod10 === 3 && mod100 !== 13) return "rd";
        return "th";
    };
    const generateUserColors = (users: any[]) => {
        const colors = [
            "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6",
            "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
            "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
            "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
            "#66994D", "#B366CC", "#4D8000", "#B33300", "#CC80CC",
            "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399",
            "#E666B3", "#33991A", "#CC9999", "#B3B31A", "#00E680",
            "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933",
            "#FF3380", "#CCCC00", "#66E64D", "#4D80CC", "#9900B3",
            "#E64D66", "#4DB380", "#FF4D4D", "#99E6E6", "#6666FF"
        ];
        const userColorMap: { [key: string]: string } = {};
        users.forEach((user, index) => {
            userColorMap[user._id] = colors[index % colors.length];
        });
        return userColorMap;
    };

    const userColorMap = generateUserColors(profiles);

    useEffect(() => {
        fetchShifts();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            refreshData();
        }, 200);
    }, [refresh]);

    useEffect(() => {
        fetchProfiles(selectedShift?.department_id);
    }, [selectedShift?.department_id]);

    useEffect(() => {
        if (selectedShift?._id) {
            const firstDay = startDate.clone().startOf('week').subtract(1, 'days').format("YYYY-MM-DD");
            const lastDay = startDate.clone().add((numberOfWeeks - 1) * 7, 'days').endOf('week').subtract(1, 'days').format("YYYY-MM-DD");
            fetchOnCallsSchedules(firstDay, lastDay);
            initializeSelectedDaysForWeeks(numberOfWeeks);
        }
    }, [selectedShift, startDate, numberOfWeeks]);

    // useEffect(() => {
    //     console.log("initialOnCallsData", initialOnCallsData)
    // }, [initialOnCallsData])

    return (
        <div className="flex flex-col w-full">

            <div className="flex flex-col w-full mt-2">
                <button
                    onClick={() => navigate(`/cruds`)}
                    className="flex flex-row gap-2 align-middles p-0 m-0 border-none bg-transparent cursor-pointer"
                >
                    <img
                        src={leftarrowsideIcon}
                        alt="toggle arrow"
                        width={40}
                    />
                    <div className="text-base font-semibold text-[#101010]">
                        {t("Back")}
                    </div>
                </button>
            </div>
            <div className="flex bg-[#E6F4FF] flex-col md:flex-row w-full gap-6 p-6 bg-gradient-to-b from-white to-[#f8f7fc] rounded-lg">
                <DeleteOnCallsModal
                    OnDelete={DeleteCall}
                    visibilityState={deleteRequestModal}
                    setState={setDeleteRequestModal}
                // content={""} // Uncomment if needed
                />
                {/* Left Section */}
                <div className="w-full md:w-[70%] bg-white p-5 rounded-lg border border-gray-200">
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
                        <div className="font-semibold text-xl text-[#6F48D7]">{t("Shift")}</div>
                        <Select
                            placeholder={t("Select")}
                            options={shifts}
                            value={selectedShift}
                            formatOptionLabel={(props) => (
                                <div className="text-sm">
                                    <div>{t("Shift")} : {props.shift_type}</div>
                                    <div>{t("ShiftFrom")} {props.start_time} {t("To")} {props.end_time}</div>
                                </div>
                            )}
                            onChange={(option) => {
                                setSelectedShift(option);
                                setNumberOfWeeks(1);
                                setCurrentWeek(1);
                                setSelectedDays([]);
                            }}
                            className="w-full md:w-[350px]"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between w-full mb-6">
                        <div className="font-semibold text-xl text-[#6F48D7]">{t("Start Date")}</div>
                        <div>

                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={(date: any) => setStartDate(moment(date))}
                                dateFormat="dd/MM/yyyy"
                                className="w-full md:w-[150px] p-2 border rounded-md"
                                placeholderText={t("Select Start Date")}
                            />
                        </div>
                    </div>

                    {selectedShift?._id && (
                        <>
                            <div className="flex flex-wrap justify-between items-center mb-6">
                                <div className="flex space-x-2">
                                    {daysOfWeek.map((day) => (
                                        <div key={day.value}>
                                            <div
                                                onClick={() => toggleDay({ value: day.value, name: day.label })}
                                                className={`w-10 h-10 flex justify-center items-center rounded-full border cursor-pointer ${selectedDays.some((d) => d.day === day.label)
                                                    ? "bg-[#6F48D7] text-white"
                                                    : "bg-gray-100 text-gray-700"
                                                    }`}
                                                title={day.label}
                                            >
                                                {day.label.charAt(0)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex items-center space-x-3">
                                    <div className="text-sm font-medium">{t("No of Weeks")}</div>
                                    <Select
                                        placeholder={t("Select")}
                                        options={weeksOptions}
                                        value={weeksOptions.find((option) => option.value === numberOfWeeks)}
                                        onChange={handleNumberOfWeeksChange}
                                        className="w-[100px]"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col w-full h-[200px] overflow-auto bg-gray-50 p-3 rounded-md">
                                {selectedDays.map((selectedDay, dayIndex) => (
                                    <div key={dayIndex} className="flex items-center justify-between p-2 mb-2 bg-white rounded-md border">
                                        <div className="text-sm">
                                            {dayIndex + 1}
                                            {getOrdinalSuffix(dayIndex + 1)} {t("Shift On")} {selectedDay.day} - {moment(selectedDay.date).format("DD-MM-YYYY")}
                                        </div>

                                        {/* Multi-Select for Multiple Users */}
                                        <Select
                                            isMulti
                                            placeholder={t("Select")}
                                            options={profiles}
                                            value={Array.isArray(selectedDay.profileId) ? selectedDay.profileId : selectedDay.profileId ? [selectedDay.profileId] : []}
                                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                            getOptionValue={(option) => option._id}
                                            onChange={(selectedOptions) => handleProfileChange(dayIndex, selectedOptions, Array.isArray(selectedDay.profileId) ? selectedDay.profileId : selectedDay.profileId ? [selectedDay.profileId] : [])}
                                            className="w-[250px]"
                                        />
                                    </div>
                                ))}
                            </div>


                            <div className="flex justify-between items-center mt-6">
                                <button
                                    className={`w-[150px] h-[40px] rounded-md border border-[#6F48D7] text-[#6F48D7] font-medium transition-all ${currentWeek === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-[#6F48D7] hover:text-white"
                                        }`}
                                    onClick={currentWeek > 1 ? handleBackWeek : undefined}
                                >
                                    {t("Back")}
                                </button>
                                <button
                                    className={`w-[150px] h-[40px] rounded-md border border-red-500 text-red-500 font-medium transition-all ${currentWeek === numberOfWeeks ? "opacity-50 cursor-not-allowed" : "hover:bg-red-500 hover:text-white"
                                        }`}
                                    onClick={currentWeek < numberOfWeeks ? handleNextWeek : undefined}
                                >
                                    {t("Next")}
                                </button>
                            </div>

                            <div className="flex flex-col space-y-4 h-[210px] overflow-auto mt-6 bg-white p-4 rounded-md border">
                                {Array.from({ length: numberOfWeeks }).map((_, weekIndex) => (
                                    <div
                                        key={weekIndex}
                                        className={`mb-4 p-3 rounded-md ${weekIndex + 1 === currentWeek ? 'bg-gray-200' : 'bg-white'}`}
                                    >
                                        <div className="font-semibold text-[#6F48D7] mb-2">{t("Week")} {weekIndex + 1}</div>
                                        <div className="flex space-x-4 overflow-x-auto">
                                            {initialOnCallsData.slice(weekIndex * 7, (weekIndex + 1) * 7).map((item, shiftIndex) => {
                                                // 🛠 Ensure profileId is an array
                                                const profiles = item.profileId
                                                    ? (Array.isArray(item.profileId) ? item.profileId : [item.profileId])
                                                    : [];
                                                console.log("profiles",profiles)
                                                //console.log("userColorMap",userColorMap)
                                                const profileColors = profiles.length > 0
                                                    ? profiles.map((p) => userColorMap[p?._id] || userColorMap[p[0]?._id])
                                                    : ["#F3F4F6"];


                                                const initials = profiles.length > 0
                                                    ? profiles.map((p) => `${p.first_name?.[0] || ""}${p.last_name?.[0] || ""}`).join(", ")
                                                    : item.day?.[0] || "";

                                                const tooltipText = profiles.length > 0
                                                    ? `${moment(item.date).format("DD-MM-YYYY")} Assigned To ${profiles.map(p => p.first_name).join(", ")}`
                                                    : `Shift for ${moment(item.date).format("DD-MM-YYYY")} Is Empty`;


                                                return (
                                                    <div key={shiftIndex} className="relative overflow-hidden">
                                                        <div className="w-12 h-12 flex justify-center items-center rounded-full border bg-gray-100"
                                                            style={{ background: `linear-gradient(45deg, ${profileColors.join(", ")})` }}
                                                            title={tooltipText}>
                                                            <span className="text-sm text-white">{initials}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </>
                    )}
                </div>

                {/* Right Section */}
                <div className="w-full md:w-[30%] bg-white p-6 rounded-md border border-gray-200">
                    <div className="flex flex-row align-middle justify-between mb-4">
                        <div className="text-lg font-semibold text-[#6F48D7] p-1">{t("Users")}</div>
                        <div className="text-lg font-semibold text-[#6F48D7] border border-[#6F48D7] hover:text-white hover:bg-[#6F48D7] transition duration-300 py-1 px-3 rounded-md cursor-pointer"
                            onClick={refreshData}
                        > {t("Refresh")}</div>
                    </div>
                    {
                        loading ? <Spinner /> : (
                            <ul className="h-[600px] overflow-y-auto space-y-3">
                                {profiles.map((profile) => {
                                    const fullName = `${profile.first_name} ${profile.last_name}`;

                                    // 🔥 Ensure profileId is an array before using .some()
                                    const count = initialOnCallsData.reduce((acc, entry) => {
                                        const profileIds = Array.isArray(entry.profileId) ? entry.profileId : entry.profileId ? [entry.profileId] : [];

                                        if (profileIds.some((p) => p._id === profile._id)) {
                                            acc++;
                                        }
                                        return acc;
                                    }, 0);

                                    const userColor = userColorMap[profile._id] || "#F3F4F6";

                                    return (
                                        <li
                                            key={profile._id}
                                            className="p-3 rounded-md"
                                            style={{
                                                backgroundColor: count > 0 ? userColor : "#F3F4F6",
                                                color: count > 0 ? "#FFFFFF" : "#000000",
                                            }}
                                        >
                                            {`${fullName}: ${count}`}
                                        </li>
                                    );
                                })}
                            </ul>
                        )
                    }

                </div>
            </div>
        </div>);



};

export default memo(OnCallsCreation);