import * as Yup from "yup";
import { useFormik } from "formik";
import errorIcon from "../../assets/errorIcon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import view from "../../assets/view.png";
import hide from "../../assets/hide.png";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import fetchWithToken from "../../utils/api";
import { setToken, updateUserProfile } from "../../utils/token";
import sideploginImage from "../../assets/sideplogin.svg";
import sideploginLogo from "../../assets/sideloginlogo.svg";
import logo4login from "../../assets/logo4login.svg";
const Login = () => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup?.object({
      email: Yup?.string()
        // .email(t('EmailInvalid'))  // Translated invalid email message
        .required(t('EmailOrUsernameRequired')),  // Translated required email message
      password: Yup.string()
        .required(t('PasswordRequired')),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetchWithToken("/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...values }),
        });
        const { token, profile } = await response;
        setToken(token);
        updateUserProfile(profile);
        navigate("/dashboard");
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        formik.setErrors({
          password:
            error?.message ||
            t("ErrorMessage"),
        });
      }
    },
  });

  useEffect(() => {
    if (Cookies.get("session")) navigate(`/dashboard`);
  }, [Cookies.get("session")]);

  return (
    <main className="flex flex-1 overflow-hidden h-[100vh] w-full items-center justify-center bg-white">
    <div className="w-[50%] h-full flex items-center justify-center">
      
      <div className=" gap-5 w-[400px] mb-16">
      <img
  src={logo4login}
  alt="logo"
  className="w-[250px] md:w-[300px] lg:w-[250px] cursor-pointer mb-32"
  onClick={() => (window.location.href = "https://qyser-landing1.vercel.app/")}
/>

    
  
        <form className="w-[350px]" onSubmit={formik?.handleSubmit}>
          
         
          <div className="pb-0 pt-1">
            <div className="text-6xl pb-1 font-bold">{t("Login")}</div>
            <div className="text-sm text-gray-600 mt-4">{t("Welcome back! Please enter your details")}</div>
          </div>
  
          <div className=" text-sm mt-5 mb-2 text-gray-600">{t("EmailOrUsername")}</div>
          <input
            placeholder={t("EnterEmailOrUsername")}
            name="email"
            className="w-full h-[40px] text-[#101010] border placeholder-[#5D6561] rounded-lg bg-[#e8f0fe] p-2 text-sm outline-none"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik?.handleBlur}
            value={formik?.values?.email}
            style={{
              borderColor: formik?.touched?.email && formik?.errors?.email ? "#E23121" : "#5D6561",
            }}
          />
          <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
            {formik?.touched?.email && formik?.errors?.email && <div>{formik?.errors?.email}</div>}
          </div>
  
          <div className="text-sm text-gray-600 mb-1">{t("Password")}</div>
  
       
          <div
            className="w-full h-[40px] border rounded-lg border-[#5D6561] flex bg-[#e8f0fe]"
            style={{
              borderColor: formik?.touched?.password && formik?.errors?.password ? "#E23121" : "#5D6561",
            }}
          >
            <input
              type={visible ? "text" : "password"}
              placeholder={t("EnterPassword")}
              className="w-full text-[#101010] bg-[inherit] placeholder-[#5D6561] rounded-lg p-2 text-sm outline-none"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.password}
            />
            <img
              src={visible ? require("../../assets/hide.png") : require("../../assets/view.png")}
              alt={visible ? "hide" : "view"}
              onClick={() => setVisible(!visible)}
              className="w-5 h-5 my-auto mx-3 cursor-pointer"
            />
          </div>
          <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
            {formik?.touched?.password && formik?.errors?.password && <div> {t("ErrorMessage")}</div>}
          </div>
  
          <div className="flex justify-between items-center">
            <div className="flex items-center ">
              <input type="checkbox" id="rememberMe" className="w-4 h-4 cursor-pointer" />
              <label htmlFor="rememberMe" className="text-sm text-gray-700 cursor-pointer">
                {t("Remember for 30 days")}
              </label>
            </div>
            <div
        onClick={() => navigate("/resetPassword")}
        className="text-sm font-semibold
         bg-gradient-to-r from-[#534DF4] to-[#AA58F2] 
         bg-clip-text text-transparent hover:from-[#6A75F7] hover:to-[#BB7AF5] 
         hover:bg-clip-text cursor-pointer"
      >
        {t("Forget Password?")}
      </div>

          </div>
  
          <button
  type="submit"
  className={`w-full rounded-lg bg-gradient-to-r from-[#534DF4] to-[#AA58F2] hover:from-[#6A75F7] hover:to-[#BB7AF5] ${
    loading ? "bg-[#1A275E] cursor-not-allowed" : ""
  } text-white p-2 text-sm mt-4 flex items-center justify-center`}
  disabled={loading}
>
  {loading ? (
    <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin bg-gradient-to-r from-[#2F3E8B] to-[#121D57]"></div>
  ) : (
    t("Loginbtn")
  )}
</button>


        </form>
      </div>
    </div>
  
    <div className="hidden md:flex h-full w-full md:w-[60%] bg-[#111D57] relative">
      <div className="absolute top-20 left-20 z-10">
        <div className="text-3xl font-bold md:text-2xl text-white">{t("Title")}</div>
        <div className="text-[14px] font-medium md:text-[12px] text-white">{t("Title2")}</div>
      </div>
  
      <img alt="sidelogo" 
      src={sideploginImage} 
      className="relative top-52 ml-20 z-10" />
  
      <img
        alt="behdingsidelogo"
        src={sideploginLogo}
        className="absolute top-10 right-0 
        transform w-[35%] md:h-[60vh] h-[200px] rounded-3xl z-0 -ml-10"
      />
    </div>
  </main>
  

  );
};
export default Login;
