import React, { useEffect, useState } from "react";
import fetchWithToken from "../../utils/api";
import { useTranslation } from "react-i18next";
import Select from "react-select";

interface Department {
  _id: string;
  name: string;
}

interface Shift {
  _id: string;
  shift_type: string;
}

interface ScheduleFilterPopupProps {
  onFilter: (filters: { departmentId: string; shiftId: string; shiftType: string; isNA: boolean }) => void;
  setPopupVisible: (visible: boolean) => void;
  currentFilters: {
    departmentId: string;
    shiftId: string;
    shiftType: string;
    isNA: boolean;
  };
}

const ScheduleFilterPopup: React.FC<ScheduleFilterPopupProps> = ({ 
  onFilter, 
  setPopupVisible,
  currentFilters 
}) => {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(currentFilters.departmentId);
  const [selectedShift, setSelectedShift] = useState<{ id: string; name: string } | null>(
    currentFilters.shiftId && currentFilters.shiftType 
      ? { id: currentFilters.shiftId, name: currentFilters.shiftType } 
      : null
  );
  const [isNA, setIsNA] = useState<boolean>(currentFilters.isNA);

  // Fetch departments
  const fetchDepartments = async () => {
    try {
      const response = await fetchWithToken("/departments", { method: "GET" });
      setDepartments(response?.data || []);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  // Fetch shifts
  const fetchShifts = async () => {
    try {
      const response = await fetchWithToken("/shifts", { method: "GET" });
      setShifts(response?.data || []);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchShifts();
  }, []);

  // Apply filters and close popup
  const handleApplyFilter = () => {
    onFilter({
      departmentId: selectedDepartment,
      shiftId: selectedShift?.id || "",
      shiftType: selectedShift?.name || "",
      isNA,
    });
    setPopupVisible(false);
  };

  // Reset filters to default values
  const handleResetFilter = () => {
    setSelectedDepartment("");
    setSelectedShift(null);
    setIsNA(false);
    onFilter({
      departmentId: "",
      shiftId: "",
      shiftType: "",
      isNA: false,
    });
    setPopupVisible(false);
  };

  const handleToggleNa = () => {
    setIsNA((prev) => !prev);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white border shadow-md rounded-lg p-4 w-96">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{t("Filter Options")}</h3>
          <button
            onClick={handleResetFilter}
            className="px-4 py-2 rounded-md border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue text-[16px] font-[700]"
          >
            {t("Rbutton")}
          </button>
        </div>

        <div className="flex justify-between items-center mb-4">
          <div className="font-semibold">{t("Show Empty Shifts")}</div>
          <div className="flex items-center gap-3">
            <button
              type="button"
              onClick={handleToggleNa}
              className={`w-[40px] h-[20px] rounded-full ${isNA ? "bg-green-500" : "bg-gray-300"}`}
            >
              <div
                className={`w-[18px] h-[18px] rounded-full bg-white transition-transform ${
                  isNA ? "translate-x-[20px]" : "translate-x-0"
                }`}
              />
            </button>
          </div>
        </div>

        {/* Department Select */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{t("Departments")}</label>
          <Select
  placeholder={t("Select department")}
  options={[
    { value: "", label: t("All Departments") }, // "All Departments" option
    ...departments.map((dept) => ({ value: dept._id, label: dept.name })),
  ]}
  value={
    selectedDepartment !== undefined
      ? {
          value: selectedDepartment,
          label:
            selectedDepartment === ""
              ? t("All Departments")
              : departments.find((d) => d._id === selectedDepartment)?.name || "",
        }
      : null
  }
  onChange={(selectedOption) => setSelectedDepartment(selectedOption?.value ?? "")}
  className="w-full h-[40px]"
/>

        </div>

        {/* Shift Select */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{t("Shift")}</label>
<Select
  placeholder={t("Select shift")}
  options={[
    { value: "", label: t("All Shifts") },
    ...shifts.map((shift) => ({ value: shift._id, label: shift.shift_type })),
  ]}
  value={
    selectedShift?.id
      ? { value: selectedShift.id, label: selectedShift.name } 
      : { value: "", label: t("All Shifts") } 
  }
  onChange={(selectedOption) => 
    setSelectedShift(
      selectedOption?.value 
        ? { id: selectedOption.value, name: selectedOption.label } 
        : null
    )
  }
  className="w-full h-[40px]"
/>

        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setPopupVisible(false)}
            className="bg-red-400 text-white px-4 py-2 rounded-md hover:bg-red-600"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleApplyFilter}
            className="bg-[darkblue] text-white px-4 py-2 rounded-md hover:bg-[#1A275E]"
          >
            {t("Apply Filters")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleFilterPopup;