import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table";
import { useEffect, useState } from "react";
import DeleteModal from "../modals/deleteModal";
import Profile from "../modals/profile";
import dp from "../../assets/dp.png";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import ImportUsers from "../modals/ImportUsers";
import UsersExport from "../modals/UsersExport";
import Spinner from "../spinner";

interface ProfilesProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  search: string;
}

interface User {
  id: number;
  username: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  id: number;
  name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
}

interface Position {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface Profile {
  _id: number;
  emp_id: string;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  profile_picture: string | null;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
  User: User;
  department: Department;
  position: Position;
  role: string;
  username: any;
}

const Profiles: React.FC<any> = ({
  isImportUser,
  setIsImportUser,
  isModalVisible,
  setModalVisible,
  isUsersExport,
  setIsUsersExport,
  search,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchProfiles = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(
        `/users?page=${currentPage}&search=${search}`,
        {
          method: "GET",
        }
      );
      

      setProfiles(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, [currentPage, count, search]);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ImportUsers
            isImportUser={isImportUser}
            setIsImportUser={setIsImportUser}
            fetchProfiles={fetchProfiles}
          />

          <UsersExport
            isUsersExport={isUsersExport}
            setIsUsersExport={setIsUsersExport}
            fetchProfiles={fetchProfiles}
          />
          <DeleteModal
            route="users"
            //content={content}

            visibilityState={deleteRequestModal}
            setState={setDeleteRequestModal}
            fetchAllCall={fetchProfiles}
          />
          <Profile
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            fetchProfiles={fetchProfiles}
          />

          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
            <TableHead className="hidden lg:table-header-group text-center">
              <TableRow className="bg-white text-center ">
                <TableHeader className="text-black ">{t("Number")}</TableHeader>
                <TableHeader className="text-black">{t("User Name")}</TableHeader>
                <TableHeader className="text-black">{t("Profiles")}</TableHeader>
                <TableHeader className="text-black">{t("Email")}</TableHeader>
                <TableHeader className="text-black">{t("Contact")}</TableHeader>
                <TableHeader className="text-black">{t("Department")}</TableHeader>
                <TableHeader className="text-black">{t("Position")}</TableHeader>
                <TableHeader className="text-black">{t("Role")}</TableHeader>
                <TableHeader className="text-black"></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(profiles) &&
                profiles.map((each, index) => {
                  
                  const rowNumber = index + 1 + (currentPage - 1) * count *2;
                  
                  return (
                    <TableRow
                      key={each?._id}
                      className="lg:table-row flex flex-col lg:flex-row  border lg:border-none rounded-lg text-center cursor-pointer hover:bg-slate-100"
                    >
                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Number")}
                      </TableCell>
                      <TableCell>{rowNumber}</TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("User Name")}
                      </TableCell>
                      <TableCell>{each?.username}</TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Profiles")}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center justify-start pl-16">
                          <img
                            alt="profile"
                            src={each?.profile_picture ?? dp}
                            className="w-[36px]"
                          />
                          <div className="flex flex-col justify-center ml-1">
                            <div className="text-[16px] font-[600] mt-0">
                              {`${each?.first_name} ${each?.last_name}`}
                            </div>
                            {/**<div className="text-[12px] -mt-2">{each?.email}</div> */}
                          </div>
                        </div>
                      </TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Email")}
                      </TableCell>
                      <TableCell>{each?.email || t("NoEmail")}</TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Contact")}
                      </TableCell>
                      <TableCell>{each?.contact || t("NoContact")}</TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Department")}
                      </TableCell>
                      <TableCell>
                        {each?.department?.name
                          ? each?.department?.name
                          : t("No Department")}
                      </TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Position")}
                      </TableCell>
                      <TableCell>
                        {each?.position?.name
                          ? each?.position?.name
                          : t("No Position Name")}
                      </TableCell>

                      <TableCell className="lg:hidden font-bold bg-gray-100">
                        {t("Role")}
                      </TableCell>
                      <TableCell>
                        {each?.role ? each?.role : t("NoRole")}
                      </TableCell>

                      <TableCell className="flex justify-end lg:w-[120px]">
                        <div
                          onClick={() => {
                            setModalVisible(each?._id);
                          }}
                          className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                        >
                          <img
                            alt="editIcon"
                            src={editIcon}
                            className="w-6 h-6"
                          />
                        </div>
                        <div
                          onClick={() => {
                            setDeleteRequestModal(each?._id);
                            setContent(
                              `${each?.first_name} ${each?.last_name}`
                            );
                          }}
                          className="w-[80px] h-full flex justify-center items-center cursor-pointer"
                        >
                          <img
                            alt="deleteIcon"
                            src={deleteIcon}
                            className="w-5 h-5"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default Profiles;
