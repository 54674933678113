import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import FailToast from "../components/modals/failToast";
import { t } from "i18next";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("session");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      FailToast(t("Session expired. Please log in again."), t);
    }
    return Promise.reject(error);
  }
);

async function fetchWithToken(
  endpoint: string,
  options: RequestInit & { responseType?: "json" | "arraybuffer" | "blob" } = {}
) {
  try {
    const response = await axiosInstance.request({
      url: endpoint,
      method: options.method || "GET",
      data: options.body,
      responseType: options.responseType || "json",
    });

    if (options.responseType === "arraybuffer") {
      return response.data;
    }

    if (options.responseType === "blob") {
      return response.data;
    }

    return response.data;

  } catch (error: unknown) {
    console.error("❌ Error during API call:", error);

    if (error instanceof AxiosError) {
      if (error.response) {
        console.log("error",error)
        FailToast(error.response.data?.message || error?.response?.data?.error||"An error occurred", t);
      } else {
        FailToast("Network error. Please try again later.", t);
      }
    } else {
      FailToast("An unexpected error occurred", t);
    }

    throw error;
  }
}

export default fetchWithToken;
