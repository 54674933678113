import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import fetchWithToken from "../../utils/api";
import Spinner from "../spinner";

interface ScheduleSummary {
  level: string;
  levelName: string;
  positionName: string;
  subDepartment: string;
  shiftName: string;
  scheduleRange: string;
  count: number;
}

interface RotationTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}

const levelNames: Record<string, string> = {
  "1": "Executive",
  "2": "Senior Manager",
  "3": "Manager",
  "4": "Supervisor",
  "5": "Senior Staff",
  "6": "Junior Staff",
  "7": "Trainee",
  // Add more level mappings as needed
};

const RotationTableSum: React.FC<RotationTableProps> = ({
  refresh,
  handleRefresh,
}) => {
  const userDepartment = useSelector(
    (state: any) => state.user.user.department
  );
  const [departments, setDepartments] = useState([]);
  const [activeDepartment, setActiveDepartment] = useState<any>();
  const [activeSubDepartment, setActiveSubDepartment] = useState<string>("");
  const [loaderHandler, setLoaderHandler] = useState<{ [id: string]: boolean }>(
    { departments: false, schedules: false }
  );
  const [summaryData, setSummaryData] = useState<ScheduleSummary[]>([]);
  const { t } = useTranslation();

  const getLevelName = (levelId: string): string => {
    return levelNames[levelId] || `Level ${levelId}`;
  };

  const fetchSchedule = async () => {
    if (!activeDepartment) return;
    setLoaderHandler({ ...loaderHandler, schedules: true });

    try {
      const query = `/schedules-rules/rotations/department?department_id=${activeDepartment ? activeDepartment : userDepartment._id
        }&limit=300${activeSubDepartment ? `&sub_department_id=${activeSubDepartment}` : ""
        }`;

      const data = await fetchWithToken(query, { method: "GET" });

      if (!data || !data.data) {
        setLoaderHandler({ ...loaderHandler, schedules: false });
        return;
      }

      if (data?.data.length === 0) {
        console.warn("API returned an empty schedule list.");
        setLoaderHandler({ ...loaderHandler, schedules: false });
        return;
      }

      // Process data to create summary
      const summaryMap = new Map<string, ScheduleSummary>();

      data.data.forEach((schedule: any) => {
        const levelId = schedule.user?.level?.toString() || '0';
        const key = `${levelId}|${schedule.sub_department?.name || 'No Subdept'}|${schedule.shift?.name || 'No Shift'}|${moment(schedule.start_date).format("DD-MM")}-${moment(schedule.end_date).format("DD-MM")}`;

        if (!summaryMap.has(key)) {
          summaryMap.set(key, {
            level: levelId,
            levelName: getLevelName(levelId),
            positionName: schedule.user?.position?.levels[0]?.name || 'No Position',
            subDepartment: schedule.sub_department?.name || 'No Subdepartment',
            shiftName: schedule.shift?.name || 'No Shift',
            scheduleRange: `${moment(schedule.start_date).format("DD-MM")} - ${moment(schedule.end_date).format("DD-MM")}`,
            count: 0
          });
        }

        const summaryItem = summaryMap.get(key);
        if (summaryItem) {
          summaryItem.count++;
          summaryMap.set(key, summaryItem);
        }
      });

      // Convert map to array and sort by level
      const sortedSummary = Array.from(summaryMap.values()).sort((a, b) => {
        // Sort by level (numeric comparison)
        const levelA = parseInt(a.level) || 0;
        const levelB = parseInt(b.level) || 0;
        return levelB - levelA; // Descending order
      });

      setSummaryData(sortedSummary);
      setLoaderHandler({ ...loaderHandler, schedules: false });
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
      setLoaderHandler({ ...loaderHandler, schedules: false });
    }
  };

  const fetchDepartments = async () => {
    setLoaderHandler({ ...loaderHandler, departments: true });

    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
          sub_departments: each?.sub_departments,
        }))
      );
      setLoaderHandler({ ...loaderHandler, departments: false });
    } catch (error) {
      console.error("Failed to fetch departments:", error);
      setLoaderHandler({ ...loaderHandler, departments: false });
    }
  };

  useEffect(() => {
    setActiveDepartment(userDepartment?._id);
  }, [userDepartment]);

  useEffect(() => {
    fetchDepartments();
    setActiveDepartment(userDepartment?._id);
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, [activeDepartment, activeSubDepartment, refresh]);

  return (
    <div className="max-w-full overflow-auto p-4">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        {loaderHandler.schedules ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          <Table className="min-w-full divide-y divide-gray-200">
            <TableHead className="bg-gray-50">
              <TableRow>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Level
                </TableHeader>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Position
                </TableHeader>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Sub-Department
                </TableHeader>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Shift
                </TableHeader>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Schedule Range
                </TableHeader>
                <TableHeader className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                  Employees Count
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className="bg-white divide-y divide-gray-200">
              {summaryData.map((item, index) => (
                <TableRow key={index} className="hover:bg-gray-50 transition-colors">
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">

                      <span className="text-sm font-medium text-gray-900">
                        {item.levelName}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {item.positionName}
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {item.subDepartment}
                    </span>
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {item.shiftName}
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {item.scheduleRange}
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <span className="px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      {item.count}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default memo(RotationTableSum);