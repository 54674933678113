import React, { useEffect, useMemo, useState } from "react";
import whitePlus from "../../assets/whitePlus.png";
import purplePlus from "../../assets/PlussC.svg"
import downArrow from "../../assets/downArrow.png";
import Search from "../../assets/searchC.svg";
import schedules from "../../assets/cruds/schedule.png";
import department from "../../assets/cruds/department.png";
import location from "../../assets/cruds/location.png";
import position from "../../assets/cruds/position.png";
import profile from "../../assets/cruds/profile.png";
import role from "../../assets/cruds/role.png";
import shift from "../../assets/cruds/shift.png";
import swap from "../../assets/cruds/swap.png";
import users from "../../assets/cruds/users.png";
import activeSchedule from "../../assets/cruds/activeSchedule.png";
import activeDepartment from "../../assets/cruds/activeDepartment.png";
import activeLocation from "../../assets/cruds/activeLocation.png";
import activePosition from "../../assets/cruds/activePosition.png";
import activeProfile from "../../assets/cruds/activeProfile.png";
import activeRole from "../../assets/cruds/activeRole.png";
import activeShift from "../../assets/cruds/activeShift.png";
import activeSwap from "../../assets/cruds/activeSwap.png";
import activeUsers from "../../assets/cruds/activeUsers.png";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/catalyst/dropdown";
import Schedules from "../../components/tabs/schedules";
import Profiles from "../../components/tabs/profiles";
import Departments from "../../components/tabs/departments";
import Positions from "../../components/tabs/positions";
import Roles from "../../components/tabs/roles";
import Shifts from "../../components/tabs/shifts";
import SwapRequests from "../../components/tabs/swapRequests";
import Locations from "../../components/tabs/locations";
import Leaves from "../../components/tabs/leaves";
import { useTranslation } from "react-i18next";
import { WeekPicker } from "../../components/weekpicker";
import fetchWithToken from "../../utils/api";
import { Select } from "@headlessui/react";
import TableFooter from "../../components/tableFooter";
import { PaginationProvider } from "../../components/PaginationProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeavesTypes from "../../components/tabs/leavesTypes";

const Cruds = () => {
  const { t, i18n } = useTranslation();
  const userRole = useSelector((state: any) => state?.user?.user?.role);
  const Myuser = useSelector((state: any) => state?.user?.user);
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>("");

  const scheduleOption = [
    {
      name: t("Monthly"),
      img: position,
      active: activeSchedule,
    },
    {
      name: t("Yearly"),
      img: department,
      active: activeProfile,
    },
  ]
  const [selectedOption, setSelectedOption] = useState(scheduleOption[0]);

  const [tabs, setTabs] = useState([
    {
      name: t("title"),
      img: schedules,
      active: activeSchedule,
      component: (props: any) => <Schedules {...props} />,
    },
    {
      name: t("User"),
      img: profile,
      active: activeProfile,
      component: (props: any) => <Profiles {...props} />,
    },
    {
      name: t("Departments"),
      img: department,
      active: activeDepartment,
      component: (props: any) => <Departments {...props} />,
    },
    {
      name: t("Positions"),
      img: position,
      active: activePosition,
      component: (props: any) => <Positions {...props} />,
    },
    // {
    //   name: t("Roles"),
    //   img: role,
    //   active: activeRole,
    //   component: (props: any) => <Roles {...props} />,
    // },
    {
      name: t("Shifts"),
      img: shift,
      active: activeShift,
      component: (props: any) => <Shifts {...props} />,
    },
    {
      name: t("SwapRequests"),
      img: swap,
      active: activeSwap,
      component: (props: any) => <SwapRequests {...props} />,
    },
    {
      name: t("Locations"),
      img: location,
      active: activeLocation,
      component: (props: any) => <Locations {...props} />,
    },
    {
      name: t("Leaves"),
      img: users,
      active: activeUsers,
      component: (props: any) => <Leaves {...props} />,
    },
    {
      name: t("LeavesTypes"),
      img: shift,
      active: activeShift,
      component: (props: any) => <LeavesTypes {...props} />,
    },
  ]);

  const [week, setWeek] = useState<any>();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSettingModalVisible, setIsSettingModalVisible] =
    useState<boolean>(false);
  const [isImportUser, setIsImportUser] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [myusers, setMyUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>("");
  const [isRotationExport, setIsRotationExport] = useState<boolean>(false);
  const [isSchedulesExport, setIsSchedulesExport] = useState<boolean>(false);
  const [isUsersExport, setIsUsersExport] = useState<boolean>(false);
  const [departments, setDepartments] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);


  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });
      setDepartments(data?.data);
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await fetchWithToken("/users", {
        method: "GET",
      });
      setMyUsers(data?.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    if (userRole === "user") {
      setTabs([
        {
          name: t("title"),
          img: schedules,
          active: activeSchedule,
          component: (props: any) => <Schedules {...props} />,
        },
      ]);
      handleTabChange({
        name: t("title"),
        img: schedules,
        active: activeSchedule,
        component: (props: any) => <Schedules {...props} />,
      });
    } else if (userRole === "manger") {
      setTabs([
        {
          name: t("title"),
          img: schedules,
          active: activeSchedule,
          component: (props: any) => <Schedules {...props} />,
        },
        {
          name: t("User"),
          img: profile,
          active: activeProfile,
          component: (props: any) => <Profiles {...props} />,
        },
        {
          name: t("Departments"),
          img: department,
          active: activeDepartment,
          component: (props: any) => <Departments {...props} />,
        },
        {
          name: t("Shifts"),
          img: shift,
          active: activeShift,
          component: (props: any) => <Shifts {...props} />,
        },
        {
          name: t("SwapRequests"),
          img: swap,
          active: activeSwap,
          component: (props: any) => <SwapRequests {...props} />,
        },
        {
          name: t("Locations"),
          img: location,
          active: activeLocation,
          component: (props: any) => <Locations {...props} />,
        },

        {
          name: t("Leaves"),
          img: users,
          active: activeUsers,
          component: (props: any) => <Leaves {...props} />,
        },
        {
          name: t("LeavesTypes"),
          img: users,
          active: activeUsers,
          component: (props: any) => <Leaves {...props} />,
        },
      ]);
    }
  }, [userRole]);

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (myusers) {
      setSelectedUser(Myuser._id);
    }
  }, [myusers]);
  const filteredUsers = useMemo(() => {
    if (selectedDepartment) {
      return myusers.filter(
        (user) =>
          user.department._id === selectedDepartment._id &&
          user.username.toLowerCase().includes(search.toLowerCase()) // Search filter
      );
    }
    return myusers.filter((user) =>
      user.username.toLowerCase().includes(search.toLowerCase())
    );
  }, [selectedDepartment, myusers, search]); // Ensure it updates when dependencies change

  useEffect(() => {
    if (filteredUsers.length > 0) {
      setSelectedUser(filteredUsers[0]._id); // Auto-select first user in filtered list
    } else {
      setSelectedUser("");
    }
  }, [filteredUsers]);


  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      const tab = tabs.find((t) => t.name === savedTab);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [tabs]);

  // Save selected tab to localStorage
  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
    localStorage.setItem("selectedTab", tab.name);
  };

  const handleClickSchedule = (tab: any) => {
    const name = tab.name;
    switch (name) {
      case "Yearly":
        // setModalVisible(!isModalVisible)
        navigate("/yearly")
        break;
      case "Monthly":
        navigate("/oncalls")
        break;

    }
  };

  return (
    <PaginationProvider>
      <div className="flex flex-1 w-[100%] h-full flex-col bg-lightgray  lg:px-20 ">
        <div className=" flex  items-center justify-between  flex-col  w-full ">
          <div className="w-full flex items-center justify-between flex-wrap flex-col xl:flex-row gap-1 md:gap-6 m-5  ">
            <div className="xl:h-[40px] h-auto flex lg:flex-row flex-col md:h-[36px] sm:h-[32px] justify-center items-center   ">
              <Dropdown>
                <DropdownButton
                  style={{
                    border: 0,
                    padding: 0,
                    // marginLeft: "55px",

                  }}
                  className="w-[280px] sm:w-[200px]"
                >
                  <div className="h-[40px] w-full relative py-2 px-4 bg-[#F7F8F7] flex hover:bg-[#E8EBE9] rounded-[5px] shadow-[2px_3px_36px_0px_#C9DFEF42]">
                    <img
                      src={selectedTab.active}
                      alt=""
                      className="w-4 h-4 mr-2 absolute top-3"
                    />
                    <div className="text-base font-semibold text-[#101010] absolute left-10">
                      {selectedTab.name}
                    </div>
                    <img
                      src={downArrow}
                      alt=""
                      className="w-3 h-2 absolute right-4 top-4"
                    />
                  </div>
                </DropdownButton>
                <DropdownMenu
                  className="bg-[white] cursor-pointer"
                  style={{
                    backgroundColor: "white",


                  }}
                >
                  {Array.isArray(tabs) &&
                    tabs.map((tab, index) => {
                      return (
                        <DropdownItem
                          className="cursor-pointer"
                          key={index}
                          onClick={() => handleTabChange(tab)}
                        >
                          <img
                            src={
                              selectedTab.name === tab.name ? tab.active : tab.img
                            }
                            alt=""
                            className={`w-4 h-4 mr-2 my-auto`}
                          />
                          <div className="text-base font-semibold text-[#101010] left-10">
                            {tab.name}
                          </div>
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>

              {userRole !== "user" && (
                <div className="h-full xl:w-[320px] md:w-[50%] w-full bg-[#FDFDFF]
               rounded-xl border  border-[#DDE8EE]
               ml-4 px-4 py-2 flex justify-between  mt-2 md:mt-0  ">
                  <input
                    type="text"
                    placeholder={t("Search")}
                    className="outline-none xl:w-[250px] w-[45%] text-sm font-normal leading-[22px] bg-[#FDFDFF] "
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img src={Search} alt="search" className="w-6 h-6 order-first " />
                </div>
              )}
            </div>

            {userRole !== "user" && (
              <div className="xl:mt-0 mt-1 flex flex-col sm:flex-row items-start sm:items-center">
                {selectedTab?.name === "Schedules" && (
                  <>
                    <Select
                      className="w-full sm:w-[150px] h-[40px] my-2 border border-[#7E8581] rounded-md sm:mr-3"
                      name="departmentId"
                      onChange={(e: any) => {
                        const selected = departments.find((dept) => dept._id === e.target.value);
                        setSelectedDepartment(selected || null);
                      }}
                    >
                      <option value="">{t("All Departments")}</option>
                      {departments.map((dept) => (
                        <option key={dept._id} value={dept._id}>
                          {dept.name}
                        </option>
                      ))}
                    </Select>

                    <Select
                      className="w-full sm:w-[150px] h-[40px]  border border-[#7E8581] rounded-md sm:mr-3"
                      name="userId"
                      onChange={(e: any) => setSelectedUser(e.target.value)}
                    >
                      {selectedDepartment
                        ? myusers
                          .filter((user) => user.department._id === selectedDepartment._id)
                          .map((user) => (
                            <option key={user?._id} value={user?._id}>
                              {user?.username}
                            </option>
                          ))
                        : myusers.map((user) => (
                          <option key={user?._id} value={user?._id}>
                            {user?.username}
                          </option>
                        ))}
                    </Select>
                  </>
                )}
                {selectedTab?.name === "Schedules" && (
                  <div className="w-full sm:w-auto mt-2 sm:mt-0">
                    <WeekPicker onChange={(e: any) => setWeek(e)} />
                  </div>
                )}
              </div>
            )}


            {selectedTab?.name === "Users" && (
              <button
                type="button"
                onClick={() => setIsImportUser(!isImportUser)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
              rounded-[40px] text-[13px] font-[500] flex items-center justify-center
               text-darkBlue border-[1px] border-darkBlue"
              >
                {t("Importusers")}
              </button>
            )}

            {selectedTab?.name === "Users" && (
              <button
                type="button"
                onClick={() => setIsUsersExport(!isUsersExport)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px]
               rounded-[40px] text-[13px] font-[500] flex items-center justify-center
                text-darkBlue border-[1px] border-darkBlue"
              >
                {t("Export Users")}
              </button>
            )}

            {selectedTab?.name === "Schedules" && (
              <button
                type="button"
                onClick={() => setIsSchedulesExport(!isSchedulesExport)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
              rounded-[40px] text-[13px] font-[500] flex items-center justify-center
               text-darkBlue border-[1px] border-darkBlue"
              >
                {t("Export Schedules")}
              </button>
            )}
            {/* {selectedTab?.name === "Schedules" && (
            <button
              type="button"
              onClick={() => navigate("/oncalls")}
              className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
              rounded-[40px] text-[13px] font-[500] flex items-center justify-center
               text-darkBlue border-[1px] border-darkBlue"
            >
              {t("On Calls")}
            </button>
          )} */}
            {/* {selectedTab?.name === "Shifts" && (
              <button
                type="button"
                onClick={() => setIsRotationExport(!isRotationExport)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
              rounded-[40px] text-[13px] font-[500] flex items-center justify-center
               text-darkBlue border-[1px] border-darkBlue"
              >
                {t("Export Rotation")}
              </button>
            )} */}

            {selectedTab?.name === "Swap Requests" && (
              <button
                type="button"
                onClick={() => setIsSettingModalVisible(!isSettingModalVisible)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px]
               rounded-[40px] text-[13px] font-[500] flex items-center justify-center
               text-darkBlue border-[1px] border-darkBlue "
              >
                {t("swapRequestSettings")}
              </button>
            )}
            {selectedTab?.name === "Schedules" ? (
              <Dropdown>
                <DropdownButton
                  style={{
                    border: 0,
                    padding: 0,
                    // marginRight: "125px",

                  }}
                  className="w-[175px]"
                >
                  <div className="h-[40px] w-full relative py-2 px-4 bg-[#F7F8F7] flex hover:bg-[#E8EBE9] rounded-[5px] shadow-[2px_3px_36px_0px_#C9DFEF42]">
                    <img
                      src={selectedOption.active}
                      alt=""
                      className="w-4 h-4 mr-2 absolute top-3"
                    />
                    <div className="text-base font-semibold text-[#101010] absolute left-10">
                      {t("Add Schedule")}
                    </div>
                    <img
                      src={downArrow}
                      alt=""
                      className="w-3 h-2 absolute right-4 top-4"
                    />
                  </div>
                </DropdownButton>
                <DropdownMenu
                  className="bg-[white] cursor-pointer"
                  style={{
                    backgroundColor: "white",


                  }}
                >
                  {Array.isArray(scheduleOption) &&
                    scheduleOption.map((tab, index) => {
                      return (
                        <DropdownItem
                          className="cursor-pointer"
                          key={index}
                          onClick={() => handleClickSchedule(tab)}
                        >
                          <img
                            src={
                              selectedTab.name === tab.name ? tab.active : tab.img
                            }
                            alt=""
                            className={`w-4 h-4 mr-2 my-auto`}
                          />
                          <div className="text-base font-semibold text-[#101010] left-10">
                            {tab.name}
                          </div>
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            ) : (
              <button
                type="button"
                onClick={() => setModalVisible(!isModalVisible)}
                className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
  rounded-[40px] text-[13px] font-[500] flex items-center justify-center 
  text-darkBlue border-[1px] border-darkBlue 
  ">
                {t("Add", { name: selectedTab?.name?.slice(0, -1) })}

                <img src={purplePlus} alt="+" className="w-3 h-3  mr-1 order-first" />
              </button>
            )}


          </div>
        </div>

        <div className="flex-1 overflow-y-auto scrollbar-hidden mt-1">
          {selectedTab?.component({
            isImportUser,
            setIsImportUser,
            setIsSchedulesExport,
            isSchedulesExport,
            setIsRotationExport,
            isRotationExport,
            isUsersExport,
            setIsUsersExport,
            isModalVisible,
            isSettingModalVisible,
            setIsSettingModalVisible,
            setModalVisible,
            week,
            selectedUser,
            user: `sa`,
            search,
          })}
        </div>
        <TableFooter />
      </div>
    </PaginationProvider>
  );
};

export default Cruds;
