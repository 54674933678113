import React from 'react';
import calendarIcon from '../../assets/calendar.svg';
import clockIcon from '../../assets/clock.svg';
import "./style.css";
import { dateToFormatedTime } from '../../utils/functions';

export interface LeaveComponentCard {
    title: string;        
    department: string;   
    startDate: string;    
    endDate: string;      
    status: string;       
    type: string;         
    note: string;         
}

function LeaveDashboardSideComponent(props: { title?: string, cards?: LeaveComponentCard[] }) {
    return (
        <div className='dashboardSideComponent'>
            <div className='main-title'>{props.title || "Leave Requests"}</div>
            <div className="main-wrapper">
                {props.cards?.map((card: LeaveComponentCard, index: number) => (
                    <div className="leaveComponentCardWrapper" key={index}>
                        <div className="title-wrapper">
                            <div className="title">{card.title || "Employee Name"}</div>
                            <div className="department">({card.department || "Department"})</div>
                        </div>
                        <div className="date-time-wrapper">
                            <div className="date-wrapper">
                                <div className="calendar-icon-wrapper">
                                    <img src={calendarIcon} alt="calendar" />
                                </div>
                                <div className='date'>
                                    {isNaN(new Date(card.startDate).getTime()) ? "Invalid Start Date" : new Date(card.startDate).toDateString()} - 
                                    {isNaN(new Date(card.endDate).getTime()) ? "Invalid End Date" : new Date(card.endDate).toDateString()}
                                </div>
                            </div>
                            <div className="time-wrapper">
                                <div className="clock-icon-wrapper">
                                    <img src={clockIcon} alt="clock" />
                                </div>
                                <div className='time'>{dateToFormatedTime(card.startDate, card.startDate)}</div>
                            </div>
                        </div>
                        <div className="status-wrapper">
                            <div className="status">{card.status || "Pending"}</div>
                        </div>
                        <div className="note-wrapper">
                            <div className="note">{card.note || "No note provided"}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LeaveDashboardSideComponent;
