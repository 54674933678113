import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import leftarrowsideIcon from "../../assets/left_arrow_F.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import fetchWithToken from "../../utils/api";
import Select, { SingleValue } from "react-select";
import SuccessToast from "../../components/modals/successToast";
import moment from "moment";
import FailToast from "../../components/modals/failToast";
import { useFormik } from "formik";
import * as Yup from "yup";
import ResponsiveRotationTable from "../../components/modals/ResponsiveRotationTable";

const RotationScreen = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const userRole = useSelector((state: any) => state?.user?.user?.role);
    const userData = useSelector((state: any) => state.user?.user);
    const [onCalls, setOnCalls] = useState<boolean>(false);
    const [profiles, setProfiles] = useState<any>([]);
    const [shifs, setShifts] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [IsMultiDays, setIsMultiDays] = useState(false);
    const [isRotation, setIsRotation] = useState(true);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [userDepartment, setUserDepartment] = useState([]);
    const [multiDays, setMultiDays] = useState<{ value: string; name: string }[]>(
        []
    );
    const [numberOfWeeks, setNumberOfWeeks] = useState(1);
    const [currentWeek, setCurrentWeek] = useState(1);

    const formik = useFormik<{
        date: string;
        profileId: SingleValue<{
            value: number;
            label: string;
            _id: string;
        }> | null;
        shiftId: SingleValue<{ value: number; label: string; _id: string }> | null;
        // deptId: SingleValue<{ value: number; label: string; _id: string }> | null;
    }>({
        initialValues: {
            date: "",
            profileId: null,
            shiftId: null,
            // deptId: null,
        },
        validationSchema: Yup.object({
            date: Yup.date().typeError(t("Invalid date format")),
            profileId: Yup.object().required(t("Profile is required")),
            shiftId: Yup.object().required(t("Shift is required")),
            // deptId: Yup.object().required(t("Department is required")),
        }),
        onSubmit: async (values) => {
            try {
                if (IsMultiDays) {
                    const numericDays = multiDays.map((day) => Number(day.value));
                    await fetchWithToken("/schedules-rules", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            daysOfWeek: numericDays,
                            user_id: values?.profileId?._id,
                            shift_id: values?.shiftId?._id,
                            start_date: moment(startDate).format("YYYY-MM-DD"),
                            end_date: moment(endDate).format("YYYY-MM-DD"),
                            // dept_id: values?.deptId?.value,//hota hon
                        }),
                    });
                    SuccessToast(t("Created"), t("Schedule created successfully"), t);
                } else {
                    await fetchWithToken(
                        "/schedules",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                date: values?.date,
                                user_id: values?.profileId?._id,
                                shift_id: values?.shiftId?._id,
                                // dept_id: values?.deptId?.value,
                            }),
                        }
                    );
                    SuccessToast(t("Created"), t("Schedule created successfully"), t);
                }
                // fetchSchedules();
            } catch (error) {
                console.error(t("Error creating schedule:"), error);
                FailToast(t("failed_to_create_schedule"), t);
            }
        },
    });


    //   const getSceduleDetails = async (id: string | number) => {
    //     try {
    //       const data = await fetchWithToken(`/schedules/${id}`, {
    //         method: "GET",
    //       });
    //       formik?.setFieldValue(
    //         "date",
    //         moment(data?.data?.date).format("YYYY-MM-DD")
    //       );
    //       formik?.setFieldValue(
    //         "profileId",
    //         profiles?.filter((each) => each?._id === data?.data?.user?._id)[0]
    //       );
    //       formik?.setFieldValue(
    //         "shiftId",
    //         shifs?.filter((each) => each?._id === data?.data?.shift?._id)[0]
    //       );
    //       // formik?.setFieldValue(
    //       //   "deptId",
    //       //   departments?.filter(
    //       //     (each) => each?._id === data?.data?.user?.department?._id
    //       //   )[0]
    //       // );
    //     } catch (error) {
    //       //console.error("Failed to fetch schedule:", error);
    //       console.error(t("Failed to fetch schedule:"), error);
    //     }
    //   };

    const fetchProfiles = async () => {
        try {
            const data = await fetchWithToken("/users", {
                method: "GET",
            });

            setProfiles(
                data?.data?.map((each: any) => ({
                    ...each,
                    value: each?._id,
                    label: each?.email,
                }))
            );
        } catch (error) {
            console.error(t("Failed to fetch profiles:"), error);
        }
    };

    const fetchShifts = async () => {
        try {
            const data = await fetchWithToken("/shifts", {
                method: "GET",
            });

            setShifts(
                data?.data?.map((each: any) => ({
                    ...each,
                    value: each?._id,
                    label: each?.shift_type,
                    startTime: each?.start_time,
                    endTime: each?.end_time,
                }))
            );

        } catch (error) {
            console.error(t("Failed to fetch shifts:"), error);
        }
    };

    const fetchUserDepartment = async () => {
        const department = userData?.department?._id
        if (!department) return; // Prevent API call if department is missing

        try {
            const data = await fetchWithToken(
                `/departments/${department}`,
                {
                    method: "GET",
                }
            );
            setUserDepartment(
                data?.data?.sub_departments?.map((each: any) => ({
                    value: each?._id,
                    label: each?.name,
                    user_level_limit: each?.user_level_limit,
                }))
            );
        } catch (error) {
            console.error(t("Failed to fetch departments:"), error);
        }
    };


    useEffect(() => {
        if (userData?.department?._id) {
            fetchUserDepartment();
        }
    }, [userData]);

    //   useEffect(() => {
    //     if (
    //       typeof isModalVisible === "number" ||
    //       typeof isModalVisible === "string"
    //     ) {
    //       getSceduleDetails(isModalVisible);
    //     }
    //   }, [isModalVisible]);

    useEffect(() => {
        fetchProfiles();
        fetchShifts();
        // fetchDepartments();
    }, []);

    const daysOfWeek = [
        { value: "0", label: t("Sat") },
        { value: "1", label: t("Sun") },
        { value: "2", label: t("Mon") },
        { value: "3", label: t("Tue") },
        { value: "4", label: t("Wed") },
        { value: "5", label: t("Thu") },
        { value: "6", label: t("Fri") },
    ];

    const toggleDay = (day: { value: string; name: string }) => {
        setMultiDays((prev) => {
            const exists = prev.find((d) => d.value === day.value);
            const newDays = exists
                ? prev.filter((d) => d.value !== day.value)
                : [...prev, day];

            return newDays;
        });
    };
    // useEffect(() => {
    //   setIsMultiDays(false);
    //   if (isAdd === true) {
    //     setIsRotation(true);
    //   } else {
    //     setIsRotation(false);
    //   }
    // }, [isAdd]);

    const handleMulti = () => {
        setIsRotation((prev) => !prev);
        setIsMultiDays(false);
    };


    const formatShiftLabelUser = (props) => {
        return (
            <div className="flex flex-col">
                <div>
                    {props?.first_name} {props?.last_name}
                </div>

            </div>
        );
    };

    const formatShiftLabel = (props) => {
        return (
            <div className="flex flex-col">
                <div>
                    {t("Shift")} : {props?.shift_type}
                </div>
                <div>
                    {t("ShiftFrom")} {props?.start_time} {t("To")} {props?.end_time}
                </div>
            </div>
        );
    };

    const handleChangeItem = (value) => {
        setIsMultiDays(value);
        setIsRotation(false);
        setOnCalls(false)
    };


    return (
        <div className="flex bg-[#E6F4FF] flex-col w-full p-6 bg-gradient-to-b from-white to-[#f8f7fc] rounded-lg">
            <div className="flex flex-col w-full">
                <button
                    onClick={() => navigate(`/cruds`)}
                    className="flex flex-row gap-2 align-middles p-0 m-0 border-none bg-transparent cursor-pointer"
                >
                    <img
                        src={leftarrowsideIcon}
                        alt="toggle arrow"
                        width={40}
                    />
                    <div className="text-base font-semibold text-[#101010]">
                        {t("Back")}
                    </div>
                </button>
            </div>
            <div className=" mt-2 flex justify-center items-center overflow-auto">
                <div className="container w-full my-auto">
                    <div className="py-5 overflow-auto m-auto w-full max-h-[1200px] capitalize rounded-[8px] flex flex-col items-center p-5 ">
                        <div className="flex flex-row justify-center sm:px-[15px] w-full gap-[30px] my-[12px]">
                            <div className="flex flex-row gap-1 items-center" onClick={handleMulti}>
                                <label className="text-[16px]">{t("rotation")}</label>
                                <input type="checkbox" className="w-[20px] h-[20px]" checked={isRotation} />
                            </div>

                            <div className="flex flex-row gap-1 items-center" onClick={() => handleChangeItem(true)}>
                                <label className="text-[16px]">{t("repeated")}</label>
                                <input type="checkbox" className="w-[20px] h-[20px]" checked={IsMultiDays && !onCalls} />
                            </div>
                        </div>

                        {IsMultiDays && (
                            <div className="font-bold mb-2">{t("Select Days")}</div>
                        )}

                        {isRotation ? (
                            <ResponsiveRotationTable userDepartment={userDepartment} />
                        ) : (
                            <div className="text-sm text-[#101010] w-full">
                                {!IsMultiDays && (
                                    <>
                                        <div className="font-bold">{t("Date")}</div>
                                        <input
                                            type="date"
                                            placeholder={t("Select date")}
                                            name="date"
                                            required
                                            className="w-full h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                                            id="date"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date}
                                        />
                                    </>
                                )}

                                <div className="font-bold">{t("Profile")}</div>
                                <Select
                                    placeholder={t("Select")}
                                    options={profiles}
                                    value={formik.values.profileId}
                                    formatOptionLabel={formatShiftLabelUser}
                                    name="profileId"
                                    onChange={(option) => formik.setFieldValue("profileId", option)}
                                    onBlur={formik.handleBlur}
                                    className="w-full h-[40px]"
                                />

                                <div className="font-bold">{t("Shift")}</div>
                                <Select
                                    placeholder={t("Select")}
                                    options={shifs}
                                    value={formik.values.shiftId}
                                    name="shiftId"
                                    onChange={(option) => formik.setFieldValue("shiftId", option)}
                                    formatOptionLabel={formatShiftLabel}
                                    onBlur={formik.handleBlur}
                                    className="w-full h-[40px]"
                                />

                                {IsMultiDays && (
                                    <>
                                        <div className="mb-4">
                                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                                                {t("Start Date")}
                                            </label>
                                            <input
                                                type="date"
                                                id="startDate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                                                {t("End Date")}
                                            </label>
                                            <input
                                                type="date"
                                                id="endDate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        <div className="w-full flex justify-center gap-4 mt-5">
                            {!isRotation && (
                                <>
                                    <button
                                        type="button"
                                        className="w-42 h-10 rounded-lg border border-darkBlue text-darkBlue text-lg font-semibold px-4 py-2 transition duration-300 hover:bg-darkBlue hover:text-white"
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        type="submit"
                                        className="w-42 h-10 rounded-lg bg-darkBlue text-white text-lg font-semibold px-4 py-2 transition duration-300 hover:bg-opacity-90"
                                    >
                                        {t("Confirm")}
                                    </button>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );



};

export default memo(RotationScreen);