import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import fetchWithToken from "../../utils/api";
import moment from "moment";
import Select from "react-select";
import { useTranslation } from "react-i18next";

interface SelectOption {
  value: string | number;
  label: string;
}

const SchedulesExport = ({
  isSchedulesExport,
  setIsSchedulesExport,
  fetchSchedules,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUser, setSelectedUser] = useState<SelectOption | null>(null);
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [exportAllUsers, setExportAllUsers] = useState(false);

  const handleExport = async () => {
    if (!startDate || !endDate) {
      alert("Please fill all fields.");
      return;
    }

    setIsLoading(true);
    try {
      const userIdQuery = exportAllUsers ? "" : `&user_id=${selectedUser?.value}`;

      const blob = await fetchWithToken(
        `/export/schedules?startDate=${startDate}&endDate=${endDate}${userIdQuery}`,
        {
          method: "GET",
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting schedules data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await fetchWithToken("/users?limit=100", {
          method: "GET",
        });
        setUsers(
          data?.data?.map((user: any) => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (isSchedulesExport) fetchUsers();
  }, [isSchedulesExport]);

  return (
    <div>
      {isSchedulesExport && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setIsSchedulesExport(false)}
        >
          <div
            className="bg-white w-[400px] p-6 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold mb-4">{t("Export Schedules")}</h3>

            <div className="flex flex-row gap-2 items-center mb-4">
              <input
                type="checkbox"
                className="w-[20px] h-[20px]"
                checked={exportAllUsers}
                onChange={(e) => setExportAllUsers(e.target.checked)}
              />
              <label className="text-[16px]">{t("Export All Users")}</label>
            </div>
            <div className="mb-4">
            {!exportAllUsers && (
              <>
              <label
                htmlFor="userSelect"
                className="block text-sm font-medium text-gray-700"
              >
                {t("SelectUser")}
              </label>
             
                <Select
                  id="userSelect"
                  options={users}
                  value={selectedUser}
                  onChange={(option: SelectOption | null) =>
                    setSelectedUser(option)
                  }
                  placeholder={t("Select")}
                  isDisabled={isLoading || exportAllUsers}


                />
                </>
              )}

            </div>

            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mb-4 w-full p-2 border border-gray-300 rounded"
            />

            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mb-4 w-full p-2 border border-gray-300 rounded"
            />

<button
  onClick={handleExport}
  disabled={isLoading}
  className="w-full rounded-[8px] bg-[darkblue] hover:bg-[#1A275E] text-white text-[16px] px-[24px] py-[8px]"
>
  {isLoading ? t("Exporting...") : t("Export Schedules")}
</button>

<button
  onClick={() => setIsSchedulesExport(false)}
  className="w-full rounded-[8px] border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue text-[16px] font-[700] px-[24px] py-[8px] mt-2"
>
  {t("Close")}
</button>

          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulesExport;
