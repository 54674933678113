import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import fetchWithToken from "../../utils/api";
import calenderR from "../../assets/calendarR.svg";
import "./style.css";
import { Select } from "@headlessui/react";
import Spinner from "../spinner";
import RotationFilter from "./rotationfilter";
import RotationExport from "../modals/RotationExport";
import { connect } from "http2";
import Rotationsummary from "./rotationsummary";
//import Rotationsummary from "./rotationsummary";

interface Block {
  block: number;
  week: string;
  date: string;
  month: string;
}

const colors = [
  "#c0b0ff",
  "#a5eebe",
  "#fcee71",
  "#f6abd8",
  "#f28585",
  "#fdd484",
  "#b8d9ff",
  "#e0e7ff", // Light pastel blue
  "#d5f9e3", // Soft mint green
  "#fff7c2", // Light buttercream yellow
  "#ffe4f3", // Pale pink
  "#f4d5ff", // Light lavender
];

const colorsMap = new Map<string, string>();

interface RotationTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}
interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift_id: string;
  user_id: string;
  department?: { name: string };
  shift?: {
    is_rotation: any;
    id: number;
    start_time: string;
    end_time: string;
    shift_type: string;
    createdAt: string;
    date: string;
    shift_name: string;
  };
  rotation?: string;
}

const RotationTable: React.FC<RotationTableProps> = ({
  refresh,
  handleRefresh,
}) => {
  const userDepartment = useSelector(
    (state: any) => state.user.user.department
  );
  const [dapartments, setDepartments] = useState([]);
  const [users, setUsers] = useState<any[]>([]);
  const [activeDepartment, setActiveDepatment] = useState<any>();
  const [activeTab, setActiveTab] = useState<any>(0);
  const [originalSchedules, setOriginalSchedules] = useState();
  const [activeShiftId, setActiveShiftId] = useState<string | null>(null);
  const [loaderHandler, setLoaderHandler] = useState<{ [id: string]: boolean }>(
    { departments: false, schedules: false }
  );
  const [activeSubDepartment, setActiveSubDepartment] = useState<string>("");
  const [isRotationExport, setIsRotationExport] = useState<boolean>(false);
  const [positions, setPositions] = useState<any>([]);
  const SelectedSchedule = useSelector(
    (state: any) => state?.user?.firstSchedule
  );
  const [week, setWeek] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [schedule, setSchedule] = useState<any>();
  const { t } = useTranslation();

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  // Generate the data for the blocks

  const generateData = (): Block[] => {
    const startDates = [
      "29-12",
      "26-01",
      "23-02",
      "23-03",
      "20-04",
      "18-05",
      "15-06",
      "13-07",
      "10-08",
      "07-09",
      "05-10",
      "02-11",
      "30-11",
    ];
    const endDates = [
      "25-01",
      "22-02",
      "22-03",
      "19-04",
      "17-05",
      "14-06",
      "12-07",
      "09-08",
      "06-09",
      "04-10",
      "01-11",
      "29-11",
      "27-12",
    ];

    const data: Block[] = startDates.map((start, index) => ({
      block: index + 1,
      week: `${start} / ${endDates[index]}`,
      date: `${start} - ${endDates[index]}`,
      month: "", // You can update this if needed
    }));

    return data;
  };

  const fetchSchedule = async () => {
    if (!activeDepartment) return;
    setLoaderHandler({ ...loaderHandler, schedules: true });

  try {
    const query = `/schedules-rules/rotations/department?department_id=${
      activeDepartment ? activeDepartment : userDepartment._id
    }&limit=300${
      activeSubDepartment ? `&sub_department_id=${activeSubDepartment}` : ""
    }`;
    
    const data = await fetchWithToken(query, { method: "GET" });

    if (!data?.data || data?.data.length === 0) {
      setLoaderHandler({ ...loaderHandler, schedules: false });
      return;
    }
    console.log("Fetched schedules:", data?.data);

      if (!data?.data || data?.data.length === 0) {
        setLoaderHandler({ ...loaderHandler, schedules: false });
        return;
      }

      setOriginalSchedules(data?.data);

      const subDepartments = activeDepartment?.sub_departments || [];
      const groupedByUser = data?.data.reduce((acc, schedule) => {
        const { user } = schedule;

        if (!user || !user._id) {
          return acc;
        }
        const userId = user._id;

        if (!acc[userId]) {
          acc[userId] = {
            id: user._id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            contact: user.contact,
            birthdate: user.birthdate,
            schedules: [],
            user_level: user.level,
          };
        }

        const subDepartmentData = subDepartments.find(
          (sub) => sub._id === schedule.sub_department_id
        );



        acc[userId].schedules.push({
          id: schedule.id,
          date: `${moment(schedule?.start_date).format("DD-MM")} - ${moment(
            schedule?.end_date
          ).format("DD-MM")}`,
          shift_type: schedule.shift.shift_type,
          start_time: schedule.shift.start_time,
          end_time: schedule.shift.end_time,
          is_rotation: schedule.shift.is_rotation,
          shift_name: schedule.shift.name,
          subdepartment: schedule?.sub_department,
        });

        return acc;
      }, {});

      const combinedSchedules = Object.values(groupedByUser);

      setSchedule(combinedSchedules || []);
      setLoaderHandler({ ...loaderHandler, schedules: false });
    } catch (error) {
      console.error(t("Failed to fetch schedules:"), error);
      setLoaderHandler({ ...loaderHandler, schedules: false });
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, [activeDepartment, activeSubDepartment]);

  const fetchDepartments = async () => {
    setLoaderHandler({ ...loaderHandler, departments: true });

    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
          sub_departments: each?.sub_departments,
        }))
      );
      setLoaderHandler({ ...loaderHandler, departments: false });
    } catch (error) {
      console.error("Failed to fetch departments:", error);
      setLoaderHandler({ ...loaderHandler, departments: false });
    }
  };

  const fetchPositions = async () => {
    try {
      const data = await fetchWithToken("/positions", { method: "GET" });
      setPositions(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?.id,
          label: each?.name,
          levels: each?.levels,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch positions:", error);
    }
  };
  useEffect(() => {
    fetchPositions();
  }, []);

  useEffect(() => {
    setActiveDepatment(userDepartment?._id);
  }, [userDepartment]);

  useEffect(() => {
    fetchDepartments();
    setActiveDepatment(userDepartment?._id);
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, [activeDepartment]);

  return (
    <div className=" max-w-full overflow-auto pl-4">
      <div className="flex items-center xl:my-2 xl:h-[40px] h-auto w-full justify-center align-middle ">
        <div className="flex xl:flex-row flex-col xl:justify-around justify-center align-middle xl:w-full h-full ">
          <div className="flex items-center xl:ml-[24px] h-[45px] rounded-[4px] px-[10px] xl:mt-0 mt-[10px] xl:mb-0 mb-[10px] bg-[#f7f8f7]">
          <div className="flex justify-center">
  <div className="inline-flex items-center gap-2 p-1 bg-gray-100 rounded-lg">
    <button
      className={`min-w-[120px] rounded-lg px-4 py-2 text-center text-sm font-medium transition-all duration-200
        ${
          activeTab === 0
            ? "bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] text-white shadow-md"
            : "bg-white text-gray-600 hover:bg-gray-50 border border-transparent hover:border-gray-200"
        }`}
      onClick={() => handleTabClick(0)}
    >
      {t("rotation_data")}
    </button>

    <button
      className={`min-w-[120px] rounded-lg px-4 py-2 text-center text-sm font-medium transition-all duration-200
        ${
          activeTab === 1
            ? "bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] text-white shadow-md"
            : "bg-white text-gray-600 hover:bg-gray-50 border border-transparent hover:border-gray-200"
        }`}
      onClick={() => handleTabClick(1)}
    >
      {t("RotationSummary")}
    </button>
  </div>
</div>

            <div className="ml-4 flex items-center">
              <RotationFilter
                dapartments={dapartments}
                setActiveDepatment={setActiveDepatment}
                activeDepartment={activeDepartment}
                setActiveSubDepartment={setActiveSubDepartment}
                activeSubDepartment={activeSubDepartment}
              />
            </div>
          </div>

          <div className="ml-[1%]">
            {activeTab === 0 && (
              <>
                {isRotationExport && (
                  <RotationExport
                    isRotationExport={isRotationExport}
                    setRotationExport={setIsRotationExport}
                  />
                )}
              </>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              setIsRotationExport((prev) => !prev);
            }}
            className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
    rounded-[40px] text-[13px] font-[500] flex items-center justify-center
    text-darkBlue border-[1px] border-darkBlue"
          >
            {t("Export Rotation")}
          </button>
        </div>
      </div>

      <div className=" ">
        {loaderHandler.schedules ? (
          <Spinner />
        ) : (
          <>
            {activeTab === 0 ? (
              <Table className="min-w-full overflow-x-auto ">
                <TableHead className="sticky top-0 bg-white z-0">
                  <TableRow>
                    <TableHeader
                      style={{
                        border: 0,
                        padding: 0,
                        transform: "translateY(5px)",
                      }}
                      className="xl:w-[60px] w-[40px] rounded-t-xl 
                 bg-gradient-to-b from-[#59CFFF] to-[#67B9FF] 
                 border border-white shadow-[2px_3px_30px_0px_#138CEB0F]"
                    >
                      <div className="flex justify-center">
                        <img
                          alt="calenderR"
                          src={calenderR}
                          className="xl:w-[30px] w-[15px]"
                        />
                      </div>
                    </TableHeader>
                    {generateData().map((block, index) => (
                      <TableHeader
                        key={block.week}
                        className="w-[80px]"
                        style={{ border: 0, padding: 5 }}
                      >
                        <div
                          className="w-full text-black h-[80px] flex flex-col items-center justify-center 
                   rounded-xl bg-[#E6F4FF] border border-[#BBDEF6]"
                        >
                          <div className="text-[10px] xl:text-[12px] font-[700] ">
                            {block.month}
                          </div>
                          <div className="text-[10px] xl:text-[12px] font-[500] my-[3px] text-[#545454]">
                            {t("block")} {index + 1}
                          </div>
                          <div className="text-[10px] xl:text-[12px] font-[400]">
                            {block.date}
                          </div>
                        </div>
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="overflow-y-auto max-h-[70vh]">
                  {schedule?.map((each) => (
                    <TableRow
                      key={each.id}
                      className="border-b border-[#E0E0E0] my-2 bg-white"
                    >
                      <TableCell
                        style={{ border: 0, padding: "10px 5px" }}
                        className="bg-white font-[600] text-[12px]"
                      >
                        <div className="h-[50px] pl-3 w-[80px] flex flex-wrap items-center">
                          <div className="mr-1">{each?.first_name}</div>
                          <div className="text-[#636363]">
                            {each?.last_name}
                          </div>
                        </div>
                      </TableCell>

                      {generateData().map((block, index) => (
                        <TableCell
                          key={block.week}
                          style={{
                            border: 0,
                            padding: 0,
                            width: "100vh",
                            overflow: "auto",
                          }}
                        >
                          {each?.schedules?.filter((schdl) => {
                            if (!colorsMap.has(schdl?.subdepartment?.name)) {
                              const color = colors[colorsMap.size];
                              colorsMap.set(schdl?.subdepartment?.name, color);
                            }
                            // if (index === 0) {
                            //   console.log("Block 1 Data:", each?.schedules);
                            // }

                            const [scheduleStart, scheduleEnd] = schdl.date
                              .split(" - ")
                              .map((date) => moment(date, "DD-MM"));
                            const [blockStart, blockEnd] = block.date
                              .split(" - ")
                              .map((date) => moment(date, "DD-MM"));

                            return (
                              scheduleStart.isSameOrBefore(blockEnd, "day") &&
                              scheduleEnd.isSameOrAfter(blockStart, "day")
                            );
                          }).length ? (
                            each?.schedules
                              ?.filter((schdl) => {
                                const [scheduleStart, scheduleEnd] = schdl.date
                                  .split(" - ")
                                  .map((date) => moment(date, "DD-MM"));
                                const [blockStart, blockEnd] = block.date
                                  .split(" - ")
                                  .map((date) => moment(date, "DD-MM"));

                                return (
                                  scheduleStart.isSameOrBefore(
                                    blockEnd,
                                    "day"
                                  ) &&
                                  scheduleEnd.isSameOrAfter(blockStart, "day")
                                );
                              })
                              .map((schdl) => (
                                <div
                                  key={schdl.id}
                                  className="ml-2 max-w-[100px] overflow-hidden"
                                >
                                  <div
                                    className="rounded-[3px] w-full h-[90px] flex flex-col justify-center"
                                    style={{
                                      background: colorsMap.get(
                                        schdl?.subdepartment?.name
                                      ),
                                      opacity: 0.8,
                                      border:
                                        schdl?.id === SelectedSchedule?.id
                                          ? "1px solid #00a843"
                                          : "",
                                    }}
                                  >
                                    <div className="font-[700] text-[10px] xl:text-[12px] mx-1">
                                      {schdl?.subdepartment?.name}
                                    </div>
                                    <div className="text-[8px] xl:text-[10px] mx-1">
                                      {`${moment(
                                        schdl?.start_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")} - ${moment(
                                        schdl?.end_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}`}
                                    </div>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <div className="flex items-center justify-center w-full max-w-[115px]">
                              <div className="bg-[#f7f8f7] rounded-[3px] w-full h-[70px] italic flex flex-col justify-center pl-3 m-1 relative" />
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Rotationsummary
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(RotationTable);
