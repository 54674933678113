import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import AnimatedBtn from "../animatedBtn";
import moment from "moment";
import { useSelector } from "react-redux";

interface Shift {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
  value: number;
  label: string;
}

interface Location {
  name: any;
  id: number;
  hospital_name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
  value: number;
  label: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
  location: Location;
  value: number;
  label: string;
}

interface User {
  id: number;
  username: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  value: number;
  label: string;
  emp_id: number;
  User: any;
}

interface ProfileProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  data?: any;
  handleCloseNewSwapRequest?:any
}

const SwapShift: any = ({ isModalVisible, setModalVisible, data,handleCloseNewSwapRequest }) => {
  const userDepartment = useSelector((state: any) => state.user.user?.department);
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    from: null,
    to: null,
  });
  const [status, setStatus] = useState<string>("");
  const [schedule, setSchedule] = useState<any>();

  useEffect(() => {

   
    if (data) {
      setInitialValues({
        from: schedule?.filter((each) => each?.id === data?.id)[0],
        to: null,
      });
    } else {
      setInitialValues({
        from: null,
        to: null,
      });
    }

  }, [data]);

  const formik = useFormik<{
    from: any | null;
    to: any | null;
  }>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      from: Yup.object().nullable().required(t("From Scedules Is Required")),
      to: Yup.object().nullable().required(t("To Scedules Is Required")),
    }),
    onSubmit: async (values) => {
      const payload = {
        "from_schedule_id": values?.from?._id,
        "to_schedule_id": values?.to?._id
      };
      setStatus("onclic");
      try {
        const response = await fetchWithToken(`/swap-requests`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        setModalVisible(!isModalVisible);
        setStatus("success");
        handleCloseNewSwapRequest()
        if (response?.message) {
          // FailToast(response?.message);
        } else {
          // SuccessToast("Requested", `Your request has been sent`);
        }
      } catch (error) {
        setStatus("fail");
        handleCloseNewSwapRequest()
        // FailToast("Failed to send swap request. Please try again.");
        console.error("Error updating shift swap", error);
      }
    },
  });

  const getSceduleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/swap-requests/${id}`, {
        method: "GET",
      });
  
      formik?.setFieldValue(
        "from",
        schedule?.filter((each) => each?._id === data?.data?.from_schedule_id)[0]
      );
      formik?.setFieldValue(
        "to",
        schedule?.filter((each) => each?._id === data?.data?.to_schedule_id)[0]
      );
   
    } catch (error) {
      console.error(t("Failed to fetch schedule:"), error);
    }
  };

  const fetchSchedules = async () => {
    try {
      const data = await fetchWithToken(
        `/schedules/department?department_id=${userDepartment._id}`,
        {
          method: "GET",
        }
      );
      setSchedule(data?.data || []);

    } catch (error) {
      console.error(t("Failed to fetch locations:"), error);
    }
  };


  useEffect(() => {
    fetchSchedules()
  }, [userDepartment]);

  useEffect(() => {
    if (isModalVisible) {
      formik.resetForm();
      if (
        typeof isModalVisible === "number" ||
        typeof isModalVisible === "string"
      ) {
    fetchSchedules()
    getSceduleDetails(isModalVisible)
      }
    }
  }, [isModalVisible]);

  const formatOptionLabel = (props) =>{
    return(
      <div className="flex flex-col">
        <div>{t("Date")} : {props?.date}</div>
        <div>{t("ShiftFrom")} {props?.shift?.start_time} {t("To")}  {props?.shift?.end_time}</div>
      </div>
    )
  };


  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 w-full max-w-md  overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold mt-[30px]"> {t("From Schedule")}</div>
            <Select
              placeholder={t("Select")}
              options={schedule}
              value={formik.values.from}
              formatOptionLabel={formatOptionLabel}
              getOptionValue={(option) => option._id.toString()}
              getOptionLabel={(option) => `${option?.date} | ${option?.shift?.start_time} - ${option.shift?.end_time}`}
              name="from"
              onChange={(option) => formik.setFieldValue("from", option)}
              onBlur={formik.handleBlur}
              
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.from &&
                formik?.errors?.from && (
                  <div>
                    {t("error on from user")}
                  </div>
                )}
            </div>

            <div className="font-bold mt-[30px]">{t("To Schedule")}</div>
            <Select
              placeholder={t("Select")}
              options={schedule}
              value={formik.values.to}
              formatOptionLabel={formatOptionLabel}
              getOptionValue={(option) => option._id.toString()}
              getOptionLabel={(option) => `${option?.date} | ${option?.shift?.start_time} - ${option.shift?.end_time}`}
              name="to"
              onChange={(option) => formik.setFieldValue("to", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.to && formik?.errors?.to && (
                <div>
                  {t("error on to user")}
                </div>
              )}
            </div>

          </div>
          <div className="w-[350px] flex gap-3 mt-[20px]">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[168px] h-[40px] rounded-[8px] border border-darkBlue text-darkBlue 
                hover:border-[#1F54A9] hover:text-[#1F54A9] 
                ${isDecline ? "text-[#56B77B] border-[#56B77B]" : ""} 
                text-[16px] font-[700] px-[24px] py-[8px]`}
              
            >
              {t("Cancel")}
            </button>
            <div className="w-[168px]">
              <AnimatedBtn
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  formik.handleSubmit();
                }}
                txt={t("Submit")}
                status={status}
                setStatus={setStatus}
              />
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default SwapShift;
