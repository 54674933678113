
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import sideploginImage from "../../assets/sideplogin.svg";
import sideploginLogo from "../../assets/sideloginlogo.svg";
import logo4login from "../../assets/logo4login.svg";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup?.object({
      email: Yup?.string()
        ?.required(t("EmailRequired")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetchWithToken("/auth/forgot-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error: any) {
        setLoading(false);
      }
    },
  });

  return (
    <main className="flex flex-1 overflow-hidden h-[100vh] w-full items-center justify-center bg-white">
      <div className="w-[50%] h-full flex items-center justify-center">
        <div className=" gap-5 w-[400px] mb-16">
          <img
            src={logo4login}
            alt="logo"
            className="w-[250px] md:w-[300px] lg:w-[250px] cursor-pointer mb-32"
          />

          <form className="w-[350px]" onSubmit={formik?.handleSubmit}>
            <div className="pb-0 pt-1">
              <div className="text-3xl pb-1 font-bold">{t("Title")}</div>
            </div>

            <div className="font-bold text-sm mt-5 mb-2 text-gray-600">{t("Email")}</div>
            <input
              placeholder={t("EnterEmail")}
              name="email"
              className="w-full h-[40px] text-[#101010] border placeholder-[#5D6561] rounded-lg bg-[#e8f0fe] p-2 text-sm outline-none"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.email}
              style={{
                borderColor: formik?.touched?.email && formik?.errors?.email ? "#E23121" : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.email && formik?.errors?.email && <div>{formik?.errors?.email}</div>}
            </div>

            <button
              type="submit"
              className={`w-full rounded-lg bg-gradient-to-r from-[#534DF4] to-[#AA58F2] hover:from-[#6A75F7] hover:to-[#BB7AF5] ${
                loading ? "bg-[#1A275E] cursor-not-allowed" : ""
              } text-white p-2 text-sm mt-4 flex items-center justify-center`}
              disabled={loading}
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin bg-gradient-to-r from-[#2F3E8B] to-[#121D57]"></div>
              ) : (
                t("Spassword")
              )}
            </button>

            

            <div className="flex mx-[30px] mt-6">
              <div className="text-xs text-[#333937] py-1">{t("Rtitle")}</div>
              <button
                onClick={() => navigate("/")}
                className="text-base  bg-gradient-to-r from-[#534DF4] to-[#AA58F2] bg-clip-text text-transparent hover:from-[#6A75F7] hover:to-[#BB7AF5] hover:bg-clip-text font-600 pl-1"
              

              >
                {t("Rbutton")}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="hidden md:flex h-full w-full md:w-[50%] bg-[#111D57] relative">
        <div className="absolute top-20 left-20 z-10">
          <div className="text-3xl font-bold md:text-2xl text-white">{t("Title")}</div>
          <div className="text-[14px] font-medium md:text-[12px] text-white">{t("Title2")}</div>
        </div>

        <img alt="sidelogo" src={sideploginImage} className="relative top-52 ml-20 z-10" />

        <img
          alt="behdingsidelogo"
          src={sideploginLogo}
          className="absolute top-10 right-0 transform w-[35%] md:h-[70vh] h-[200px] rounded-3xl z-0 -ml-10"
        />
      </div>
    </main>
  );
};

export default ResetPassword;
