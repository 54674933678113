import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import PositionModal from "../modals/positionModal";
import DeleteModal from "../modals/deleteModal";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import Spinner from "../spinner";

interface rolesProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Positions: React.FC<rolesProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const [deletePositionModal, setDeletePositionModal] = useState<
    boolean | number | string
  >(false);
  const { t, i18n } = useTranslation();
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [positions, setPositions] = useState<any[]>([]);
  const [content, setContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchPositions = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(`/positions?page=${currentPage}`, {
        method: "GET",
      });
      setPositions(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit)
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch positions:", error);
      setIsLoading(false);

    }
  };

  useEffect(() => {
    fetchPositions();
  }, [currentPage, count]);

  return (
    <>
      {isLoading ? <Spinner /> : <>
        <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
          <TableHead className="hidden lg:table-header-group w-full text-center">
            <TableRow className="bg-white w-full">
              <TableHeader className="text-black">{t("Number")}</TableHeader>
              <TableHeader className="text-black">{t("Position")}</TableHeader>
              <TableHeader></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="w-full">
            {Array.isArray(positions) &&
              positions?.map((position, index) => {
                const rowNumber = index + 1 + (currentPage - 1) * count;

                return (
                  <TableRow
                    key={position.id}
                    className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full text-center cursor-pointer hover:bg-slate-100"
                  >
                    <TableCell className="lg:hidden font-bold bg-gray-100">
                      {t("Number")}
                    </TableCell>
                    <TableCell className="">{rowNumber}</TableCell>
                    <TableCell className="lg:hidden font-bold bg-gray-100 ">
                      {t("Position")}
                    </TableCell>
                    <TableCell className="w-full">{position?.name}</TableCell>

                    <TableCell className="flex justify-end w-full">
                      <div
                        onClick={() => setModalVisible(position.id)}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                      </div>
                      <div
                        onClick={() => {
                          setDeletePositionModal(position.id);
                          setContent(position.position_name);
                        }}
                        className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                      >
                        <img
                          alt="deleteIcon"
                          src={deleteIcon}
                          className="w-6 h-6"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <PositionModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          fetchPositions={fetchPositions}
        />
        <DeleteModal
          route="positions"
          //content={content}

          visibilityState={deletePositionModal}
          setState={setDeletePositionModal}
          fetchAllCall={fetchPositions}
        />
      </>}

    </>
  );
};

export default Positions;
