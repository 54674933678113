import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import filterIcon from "../../assets/filterswapr.svg";
import { useSelector } from "react-redux"; // Import useSelector for Redux state

interface SubDepartment {
  _id: string;
  name: string;
}

interface Department {
  _id: string;
  name: string;
  sub_departments: SubDepartment[];
}

interface RotationFilterProps {
  dapartments: Department[];
  setActiveDepatment: (departmentId: string) => void;
  activeDepartment: string;
  setActiveSubDepartment: (subDeptId: string) => void;
  activeSubDepartment: string;
}

const RotationFilter: React.FC<RotationFilterProps> = ({
  dapartments,
  setActiveDepatment,
  activeDepartment,
  setActiveSubDepartment,
  activeSubDepartment,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(activeDepartment || "");
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(activeSubDepartment || "");
  const { t } = useTranslation();
  const userRole = useSelector((state: any) => state?.user?.user?.role);
    // Sync local state with props
  useEffect(() => {
    setSelectedDepartment(activeDepartment);
    setSelectedSubDepartment(activeSubDepartment);
  }, [activeDepartment, activeSubDepartment]);

  const toggleModal = () => setIsVisible(!isVisible);

  const handleApplyFilter = () => {
    setActiveDepatment(selectedDepartment);
    setActiveSubDepartment(selectedSubDepartment);
    toggleModal();
  };

  const handleResetFilter = () => {
    setSelectedDepartment("");
    setSelectedSubDepartment("");
    setActiveDepatment("");
    setActiveSubDepartment("");
    toggleModal();
  };

  const selectedDep = dapartments.find((dep) => dep._id === selectedDepartment);

  // Check user role and show filters accordingly
  const isAdmin = userRole === "admin";
  const isManager = userRole === "manager";
  const isUser = userRole === "user"; // Assuming user role is "user"

  return (
    <div>
      {isUser ? null : ( // Don't show the filter for users
        <div className="flex items-center ml-4 cursor-pointer" onClick={toggleModal}>
          <img src={filterIcon} alt="Filter" className="w-6 h-6" />
        </div>
      )}

      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white border shadow-md rounded-lg p-4 w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t("Filter Options")}</h3>
              <button
                onClick={handleResetFilter}
                className="px-4 py-2 rounded-md border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue text-[16px] font-[700]"
              >
                {t("Reset")}
              </button>
            </div>

            {isAdmin && ( // Show department and sub-department selects for admins
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t("Departments")}
                  </label>
                  <Select
                    className="w-full h-[40px] border border-[#7E8581] rounded-md"
                    options={dapartments?.map((dep) => ({
                      value: dep._id,
                      label: dep.name,
                    }))}
                    value={selectedDepartment ? {
                      value: selectedDepartment,
                      label: dapartments.find(d => d._id === selectedDepartment)?.name || ""
                    } : null}
                    onChange={(selectedOption: any) => {
                      setSelectedDepartment(selectedOption?.value || "");
                      setSelectedSubDepartment(""); // Reset sub-department when changing department
                    }}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t("Sub Departments")}
                  </label>
                  <Select
                    className="w-full h-[40px] border border-[#7E8581] rounded-md"
                    options={[{ value: "", label: t("All Sub Departments") }, ...(selectedDep?.sub_departments || []).map((subDep) => ({
                      value: subDep._id,
                      label: subDep.name,
                    }))]}
                    value={selectedSubDepartment ? {
                      value: selectedSubDepartment,
                      label: selectedDep?.sub_departments?.find(
                        (subDep) => subDep._id === selectedSubDepartment
                      )?.name || ""
                    } : { value: "", label: t("All Sub Departments") }}
                    onChange={(selectedOption: any) => 
                      setSelectedSubDepartment(selectedOption?.value || "")
                    }
                    isDisabled={!selectedDepartment}
                  />
                </div>
              </>
            )}

            {isManager && selectedDepartment && ( // Managers only see their sub-department
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t("Sub Departments")}
                </label>
                <Select
                  className="w-full h-[40px] border border-[#7E8581] rounded-md"
                  options={[{ value: "", label: t("All Sub Departments") }, ...(selectedDep?.sub_departments || []).map((subDep) => ({
                    value: subDep._id,
                    label: subDep.name,
                  }))]}
                  value={selectedSubDepartment ? {
                    value: selectedSubDepartment,
                    label: selectedDep?.sub_departments?.find(
                      (subDep) => subDep._id === selectedSubDepartment
                    )?.name || ""
                  } : { value: "", label: t("All Sub Departments") }}
                  onChange={(selectedOption: any) => 
                    setSelectedSubDepartment(selectedOption?.value || "")
                  }
                  isDisabled={!selectedDepartment}
                />
              </div>
            )}

            <div className="flex justify-between mt-4">
              <button
                onClick={toggleModal}
                className="bg-red-400 text-white px-4 py-2 rounded-md hover:bg-red-600"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleApplyFilter}
                className="bg-[darkblue] text-white px-4 py-2 rounded-md hover:bg-[#1A275E]"
              >
                {t("Apply Filters")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RotationFilter;
