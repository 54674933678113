import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import calenderDark from "../../assets/calenderDark.png";
import "./style.css";
import { WeekPicker } from "../weekpicker";
import moment from "moment";
import SwapRequests from "../SwapRequests";
import calender from "../../assets/calender.png";
import requestBtn from "../../assets/requestBtn.png";
import clock from "../../assets/clock.png";
import stethoscope from "../../assets/stethoscope.png";
import locationimg from "../../assets/location.png";
import reqMsg from "../../assets/reqMsg.png";
import AddNoteModal from "../modals/AddNoteModal";
import SuccessToast from "../modals/successToast";
import FailToast from "../modals/failToast";
import Leave from "../modals/leave";
import fetchWithToken from "../../utils/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Select } from "@headlessui/react";
import ShiftDetailsForSwap from "../modals/shiftDetailsForSwap";
import Spinner from "../spinner";
import ScheduleFilterPopup from "../modals/ScheduleFilterPopup";
import filterIcon from "../../assets/filterswapr.svg"; // Import your filter image
import OnCallsTable from "../modals/oncallstable/oncallstable";
import { PaginationProvider } from "../PaginationProvider";
import DashboardSideComponent from "../dashboardSideComponent/dashboardSideComponent";
import LeaveDashboardSideComponent, { LeaveComponentCard } from "./leavesDash";

const colors = [
  "#c0b0ff",
  "#a5eebe",
  "#fcee71",
  "#f6abd8",
  "#f28585",
  "#fdd484",
  "#b8d9ff",
];
interface ScheduleTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}
interface LeaveDetails {
  end_date: any;
  status: string;
  note: string;
  type: string;
  start_date: string;
  // Add other properties as needed
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  refresh,
  handleRefresh,
}) => {
  const SelectedSchedule = useSelector(
    (state: any) => state?.user?.firstSchedule
  );
  const userDepartment = useSelector(
    (state: any) => state.user.user.department
  );
  const currentUserCode = useSelector(
    (state: any) => state.user?.user?.role?.code
  );
  const [shiftDetailsModal, setShiftDetailsModal] = useState<
    boolean | string | number
  >(false);
  const [selectedSwapRequest, setSelectedSwapRequest] = useState();
  const [action, setAction] = useState<boolean>(false);
  const [week, setWeek] = useState<any>();
  const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const [originalSchedules, setOriginalSchedules] = useState();
  const [swapRequests, setSwapRequests] = useState<any>([]);
  const [loaderHandler, setLoaderHandler] = useState<{ [id: string]: boolean }>(
    { departments: false, schedule: false, swapRequests: false }
  );
  const [activeTab, setActiveTab] = useState<any>(0);
  const [schedule, setSchedule] = useState<any>();
  const [departments, setDepartments] = useState([]);
  const [activeDepartment, setActiveDepatment] = useState(userDepartment?._id);
  const firstDay = useMemo(() => moment(week?.firstDay), [week]);
  const lastDay = useMemo(() => moment(week?.lastDay), [week]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [onCallsData, setOnCallsData] = useState<any[]>([]);
  const [startDate] = useState(moment().startOf("week").format("YYYY-MM-DD"));
  const [endDate] = useState(moment().endOf("week").format("YYYY-MM-DD"));
  const [isLoading, setIsLoading] = useState(false);  // Define isLoading state
  const [leaves, setLeaves] = useState<LeaveDetails[]>([]);
  const [selectedShift, setselectedShift] = useState([]);
  const [leavesCards, setLeavesCards] = useState<LeaveComponentCard[]>([]);

  const [filters, setFilters] = useState<{
    departmentId: string;
    shiftId: string;
    shiftType: string;
    isNA: boolean;
  }>({
    departmentId: "",
    shiftId: "",
    shiftType: "",
    isNA: false,
  });
  const daysOfWeek = useMemo(
    () => [
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
      t("Sunday"),
    ],
    [t]
  );

  const weekDates = daysOfWeek.map((_, index) =>
    firstDay.clone().add(index, "days")
  );

  let displayMonth;
  if (firstDay.isSame(lastDay, "month")) {
    // Same month
    displayMonth = firstDay.format("MMMM YYYY");
  } else if (firstDay.isSame(lastDay, "year")) {
    // Different months, same year
    displayMonth = `${firstDay.format("MMMM")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  } else {
    // Different months and different years
    displayMonth = `${firstDay.format("MMMM YYYY")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  }

  const handleFilter = (newFilters: {
    departmentId: string;
    shiftId: string;
    shiftType: string;
    isNA: boolean;
  }) => {
    setFilters(newFilters);
  };

  const fetchSchedule = async (dateFrom: string, dateTo: string) => {
    const formattedDateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const formattedDateTo = moment(dateTo).format("YYYY-MM-DD");
    if (!activeDepartment) return;
    setLoaderHandler({ ...loaderHandler, schedule: true });
    try {
      const data = await fetchWithToken(
        `/schedules/department?startDate=${formattedDateFrom}&endDate=${formattedDateTo}&department_id=${activeDepartment ? activeDepartment : userDepartment._id
        }`,
        {
          method: "GET",
        }
      );


      setOriginalSchedules(data?.data);
      const groupedByUser = data?.data.reduce((acc, schedule) => {
        const { user, _id: scheduleId } = schedule;
        const userId =
          user?.id || user?._id || `${user?.first_name}-${user?.last_name}`;

        if (!acc[userId]) {
          acc[userId] = {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            contact: user.contact,
            birthdate: user.birthdate,
            schedules: [],
          };
        }

        acc[userId].schedules.push({
          id: scheduleId,
          date: schedule.date,
          shift_type: schedule.shift.shift_type,
          start_time: schedule.shift.start_time,
          end_time: schedule.shift.end_time,
        });

        return acc;
      }, {});

      const combinedSchedules = Object.values(groupedByUser);
      setSchedule(combinedSchedules || []);
      setLoaderHandler({ ...loaderHandler, schedule: false });
    } catch (error) {
      console.error(t("Failed to fetch locations:"), error);
      setLoaderHandler({ ...loaderHandler, schedule: false });
    }
  };

  const fetchDepartments = async () => {
    setLoaderHandler({ ...loaderHandler, departments: false });
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });
      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );

      setLoaderHandler({ ...loaderHandler, departments: false });
    } catch (error) {
      console.error("Failed to fetch departments:", error);
      setLoaderHandler({ ...loaderHandler, departments: false });
    }
  };

  useEffect(() => {
    setActiveDepatment(userDepartment?._id);
  }, [userDepartment]);

  useEffect(() => {
    fetchSchedule(
      moment(week?.firstDay).format("YYYY-MM-DD"),
      moment(week?.lastDay).format("YYYY-MM-DD")
    );
  }, [activeDepartment]);

  useEffect(() => {
    if (!week?.firstDay || !week?.lastDay) return;
    fetchSchedule(
      moment(week?.firstDay).format("YYYY-MM-DD"),
      moment(week?.lastDay).format("YYYY-MM-DD")
    );
    fetchDepartments();
  }, [week]);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const fetchSwapRequests = async () => {
    setLoaderHandler({ ...loaderHandler, swapRequests: true });
    try {
      const data = await fetchWithToken("/swap-requests?status=pending", {
        method: "GET",
      });
      setSwapRequests(data?.data?.from_user);
      setLoaderHandler({ ...loaderHandler, swapRequests: false });
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
      setLoaderHandler({ ...loaderHandler, swapRequests: false });
    }
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [refresh]);

  const handleAccept = async (each: SwapRequests) => {
    try {
      const response = await fetchWithToken(
        `/swapRequests/acceptswap/${each?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        handleRefresh();
      }
      // SuccessToast(
      //   "Accepted",
      //   `You accepted request from ${each?.first_name_from} ${each?.last_name_from}`
      // );
    } catch (error) {
      // FailToast("Accept swap failed. Please try again.");
      console.error("Accept swap failed :", error);
    }
  };


  const fetchDepartmentsAndLeaves = async () => {
    setIsLoading(true);
    try {
      const departmentData = await fetchWithToken("/departments", { method: "GET" });
      const departments = departmentData?.data?.map((each: any) => ({
        ...each,
        value: each?._id,
        label: each?.name,
      })) || [];
      //console.log("Departments data:", departments);
      setDepartments(departments);

      // Fetch leaves
      const leavesData = await fetchWithToken(`/leaves`, { method: "GET" });
      const leaves = leavesData?.data || [];

      // Map leaves and match department names
      const mappedLeaves = mapLeavesCards(leaves, departments);

      setLeaves(leaves);
      setLeavesCards(mappedLeaves);

      // console.log("Leaves data:", leaves);
    } catch (error) {
      console.error("Failed to fetch departments or leaves:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDepartmentsAndLeaves();
  }, []);

  const mapLeavesCards = (leaves: any[], departments: any[]): LeaveComponentCard[] => {
    return leaves.map((item: any) => {
      const department = departments.find(dep => dep._id === item?.user_id.department) || {};
      console.log("Department23:", department);
      return {
        title: `${item?.user_id?.first_name ?? "No First Name"} ${item?.user_id?.last_name ?? "No Last Name"}`,
        department: department.name ?? "No Department",  // Correctly fetching department name
        startDate: item?.start_date ?? "No Start Date",
        endDate: item?.end_date ?? "No End Date",
        status: item?.status ?? "No Status",
        type: item?.type ?? "No Leave Type",
        note: item?.note ?? "No Note Provided",
      };
    });
  };



  //swap details time difference
  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    //return now.diff(createdMoment, "minutes") + " " + t("min ago")
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);

    if (days === 1) {
      return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
      return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
      return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
      return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
      return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
      return t("minutes_ago", { minutes });
    } else {
      return t("now");
    }
  };

  const handleShowNotes = (data: any, action) => {
    setAction(action);
    setAddNoteModalVisible(true);
    setSelectedSwapRequest(data);
  };

  return (
    <div className="h-full xl:ml-[16px]">
      <AddNoteModal
        approved={action}
        data={selectedSwapRequest}
        isModalVisible={isAddNoteModalVisible}
        setModalVisible={setAddNoteModalVisible}
        handleRefresh={handleRefresh}
      />
      <Leave
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        fetchLeaves=""
      />
      <ShiftDetailsForSwap
        isModalVisible={shiftDetailsModal}
        setModalVisible={setShiftDetailsModal}
        schedules={originalSchedules}
      />

      <div className="flex items-center xl:my-2 xl:h-[40px] h-auto w-full justify-center align-middle ">
        <div className="flex xl:flex-row flex-col xl:justify-around justify-center align-middle xl:w-full h-full ">
          <p className="text-[20px] font-[700] xl:mr-[5%] mt-1 xl:w-[100px] text-nowrap text-center">
            {t("AvailableShifts")}
          </p>
          <div className="xl:mt-0 mt-1">
            {activeTab !== 2 && activeTab !== 1 && (
              <WeekPicker onChange={(e: any) => setWeek(e)} />
            )}
          </div>
          <div className="flex items-center xl:ml-[24px] h-[45px] rounded-[4px] px-[10px] xl:mt-0 mt-[10px] xl:mb-0 mb-[10px]  bg-[#f7f8f7]">
            <div
              className={`xl:w-[80px] w-[50%] mr-[8px] rounded-[4px] p-1 flex justify-center cursor-pointer ${activeTab === 0
                  ? "bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] text-white hover:opacity-90"
                  : "bg-[#f7f8f7] text-black hover:border-darkBlue border"
                }`}
              onClick={() => handleTabClick(0)}
            >
              {t("CalenderButton")}
            </div>
            <div
              className={`xl:w-[120px] w-[90%] mr-[8px] rounded-[4px] p-1 flex justify-center cursor-pointer ${activeTab === 1
                  ? "bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] text-white hover:opacity-90"
                  : "bg-[#f7f8f7] text-black hover:border-darkBlue border "
                }`}
              onClick={() => handleTabClick(1)}
            >
              {t("GridButton")}
            </div>
            <div
              className={`xl:w-[80px] w-[50%] mr-[8px] rounded-[4px] p-1 flex justify-center cursor-pointer ${activeTab === 2
                  ? "bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] text-white hover:opacity-90"
                  : "bg-[#f7f8f7] text-black hover:border-darkBlue border"
                }`}
              onClick={() => handleTabClick(2)}
            >
              {t("Oncallss")}
            </div>
            {activeTab === 2 && (
              <div
                className="flex items-center ml-4 cursor-pointer"
                onClick={() => setPopupVisible(true)}
              >
                <img src={filterIcon} alt="Filter" className="w-6 h-6" />
              </div>
            )}

            {isPopupVisible && (
              <ScheduleFilterPopup
                onFilter={handleFilter}
                setPopupVisible={setPopupVisible}
                currentFilters={filters}
              />
            )}
          </div>
          {currentUserCode === "admin" && (
            <div className="mb-2 xl:mb-0 ">
              <Select
                className="xl:w-[110px] w-[250px] h-[40px] border border-[#7E8581] rounded-md ml-2"
                name="deptId"
                onChange={(e: any) => setActiveDepatment(e.target.value)}
                value={activeDepartment}
              >
                {loaderHandler.departments ? (
                  <>
                    {" "}
                    <Spinner />{" "}
                  </>
                ) : (
                  departments?.map((dep: any) => (
                    <option key={dep?._id} value={dep?._id}>
                      {dep?.name}
                    </option>
                  ))
                )}
              </Select>
            </div>
          )}
          <div className="ml-[1%]">
            <button
              onClick={() => setIsModalVisible(true)}
              className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] 
                        rounded-[40px] text-[13px] font-[500] text-darkBlue border-[1px] 
                      border-darkBlue px-[16px] flex items-center justify-center gap-[5px] mr-2"
            >
              {t("leaveRequestB")}
              <span>+</span>
            </button>
          </div>
        </div>
      </div>
      {activeTab === 0 && (
        <div className="xl:overflow-y-scroll overflow-scroll xl:mt-0 mt-2 scrollbar-hidden h-[90%]">
          <Table className={"w-[100%]"}>
            <TableHead>
              <TableRow>
                <TableHeader
                  style={{
                    border: 0,
                    padding: 0,
                  }}
                  className="xl:w-[100px] xl:min-w-[100px] xl:max-w-[100px] w-[20px] min-w-[20px] max-w-[20px]"
                >
                  <div className="flex justify-center">
                    <img
                      alt="calenderDark"
                      src={calenderDark}
                      className="xl:w-[20px] w-[10px]"
                    />
                  </div>
                </TableHeader>
                {weekDates?.map((date, index) => (
                  <TableHeader
                    key={daysOfWeek[index] + index}
                    className="w-[120px]"
                    style={{
                      border: 0,
                      padding: 5,
                    }}
                  >
                    <div className="xl:w-full w-[40px] text-[black] xl:h-[70px] h-[30px] flex flex-col items-center justify-center rounded-[4px] bg-[#e8ebe9]">
                      <div className="xl:text-[16px] text-[7px] font-[600]">
                        {daysOfWeek[index]}
                      </div>
                      <div className="hidden xl:flex text-[24px] font-[500] mt-1">
                        {date.format("DD")}
                      </div>
                    </div>
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="cursor-pointer">
              {!!loaderHandler.schedule ? (
                <>
                  {" "}
                  <Spinner />{" "}
                </>
              ) : (
                schedule?.map((each: any, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: 0, padding: 0 }}
                      className={"bg-[#f7f8f7] font-[600] text-[12px]"}
                    >
                      <div className="h-[50px] pl-3 w-[100px] flex flex-wrap items-center">
                        <div className="mr-1">{each?.first_name}</div>
                        <div className="mr-1">{each?.last_name}</div>
                      </div>
                    </TableCell>
                    {weekDates?.map((val, index) => (
                      <TableCell
                        key={index}
                        style={{
                          border: 0,
                          padding: 0,
                        }}
                      >
                        {each?.schedules?.filter(
                          (schdl: any) =>
                            moment(schdl?.date)?.format("DD") ===
                            val?.format("DD")
                        )?.length ? (
                          each?.schedules
                            ?.filter(
                              (schdl: any) =>
                                moment(schdl?.date)?.format("DD") ===
                                val?.format("DD")
                            )
                            ?.map((schdl: any) => (
                              <div
                                key={schdl?.id}
                                className="flex items-center justify-center"
                                onClick={() => {
                                  console.log("schedule test", schdl);
                                  setShiftDetailsModal(schdl ?? false);
                                }}
                              >
                                <div
                                  style={{
                                    background: colors[index],
                                    opacity:
                                      val?.diff(moment(), "d") < 0 ? 0.3 : 0.9,
                                    border:
                                      schdl?.id === SelectedSchedule?.id
                                        ? "2px solid #00a843"
                                        : "",
                                  }}
                                  className={` rounded-[3px] w-full h-[60px] italic flex flex-col justify-center pl-3 m-1 relative`}
                                >
                                  <div className="font-[600] text-[14px]">
                                    {userDepartment?.name}
                                  </div>
                                  <div className="text-[12px] -mt-1">{`${moment(
                                    schdl?.start_time,
                                    "HH:mm:ss"
                                  ).format("ha")} - ${moment(
                                    schdl?.end_time,
                                    "HH:mm:ss"
                                  ).format("ha")}`}</div>
                                  <div className="text-[12px] mt-1">
                                    {schdl?.shift_type || "N/A"}
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="flex items-center justify-center w-full">
                            <div
                              className={`bg-[#f7f8f7] rounded-[3px] w-full h-[60px] italic flex flex-col justify-center pl-3 m-1 relative`}
                            />
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      )}
      {activeTab === 1 && (
        <div className="overflow-scroll mt-6 scrollbar-hidden max-h-[85%]">
          <LeaveDashboardSideComponent
            title={t("Upcoming Leaves")}
            cards={leavesCards}

          />
        </div>
      )}
      {activeTab === 2 && (
        <div className=" overflow-y-scroll   p-4 w-full h-5/6">
          <PaginationProvider>
            <OnCallsTable
              activeTab={activeTab}
              selectedOnCallsShift={selectedShift}
              filters={filters}
            />
          </PaginationProvider>
        </div>
      )}
    </div>
  );
};

export default ScheduleTable;
