import React, { useEffect, useState } from "react";
import calender from "../assets/calender.png";
import request from "../assets/request.png";
import requestBtn from "../assets/requestBtn.png";
import message from "../assets/message.png";
import redClose from "../assets/RedClose.png";
import greenCorrect from "../assets/greenCorrect.png";
import warn from "../assets/Warning3.png";
import moment from "moment";
import AddNoteModal from "./modals/AddNoteModal";
import SwapShift from "./modals/swapShift";
import SuccessToast from "./modals/successToast";
import FailToast from "./modals/failToast";
import Tooltip from "./tooltip";
import fetchWithToken from "../utils/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CancelModal from "./modals/CancelModal";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string | null;
  profilePicture: string | null;
  emp_id: number;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
}

interface Schedule {
  id: number;
  date: string;
  profileId: number;
  shiftId: number;
  deptId: number;
  createdAt: string;
  updatedAt: string;
  profile: Profile | null;
}

interface Swap {
  message: string;
  from_schedule: any;
  to_user: any;
  from_user: any;
  _id: number;
  schedule_from: number;
  to_schedule: any;
  schedule_to: number;
  req_message: string;
  accepted: boolean;
  priority: number | null;
  notes: string | null;
  createdswap: boolean;
  createdAt: string;
  updatedAt: string;
  scheduleFrom: Schedule;
  scheduleTo: Schedule;
  status: string;
  type?: string;
}

interface Option {
  name: string;
  value: string;
}

interface ScheduleTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}

const Requests: React.FC<ScheduleTableProps> = ({ refresh, handleRefresh }) => {
  const userId = useSelector((state: any) => state?.user?.user?._id);
  const [swapRequests, setSwapRequests] = useState<Swap[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<Option[]>([]);
  const [filterText, setFilterText] = useState("");
  const { t, i18n } = useTranslation();
  const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [selectedSwapRequest, setSelectedSwapRequest] = useState();
  const [openSwapRequest, setOpenSwapRequest] = useState(false);
  const [action, setAction] = useState<boolean>(false);

  const fetchSwapRequests = async () => {
    try {
      const data = await fetchWithToken("/swap-requests?status=pending", {
        method: "GET",
      });

      // const mergedArray: any[] = []; // Explicitly define it as an array of any

      // // Helper function to push data with type
      // const addDataWithType = (array: any[] | undefined, type: string) => {
      //   (array || []).forEach((item) => {
      //     mergedArray.push({ ...item, type });
      //   });
      // };

      // addDataWithType(data?.data?.from_user, "from_user");
      // addDataWithType(data?.data?.to_user, "to_user");
      // addDataWithType(data?.data?.manager, "manager");
      // addDataWithType(data?.data?.department, "department");

      swapRequestsFilter(data?.data);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  const swapRequestsFilter = (data: any) => {
    let arr = data;
    if (selectedDepartments?.length) {
      arr = arr?.filter((item: any) =>
        selectedDepartments?.some(
          (dept) =>
            item?.scheduleFrom?.deptId?.toString() ===
            dept?.value?.toString() ||
            item?.scheduleFrom?.deptId?.toString() === dept?.value?.toString()
        )
      );
    }
    setSwapRequests(arr);
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [selectedDepartments, filterText, refresh]);

  const handleShowNotes = (data: any, action) => {
    setAction(action)
    setAddNoteModalVisible(true);
    setSelectedSwapRequest(data);
  };

  const handleShowCancelModal = (data: any, action) => {
    setIsCancelModalVisible(true);
    setSelectedSwapRequest(data);
  };


  //Swap Requests time on the right in swap
  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    //return now.diff(createdMoment, "minutes") + " " + t("min ago")
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);

    if (days === 1) {
      return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
      return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
      return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
      return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
      return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
      return t("minutes_ago", { minutes });
    } else {
      return t("now");
    }
  };


  const handleOpenNewSwapRequest = () => {
    setOpenSwapRequest(true);
  };

  const handleCloseNewSwapRequest = () => {
    setOpenSwapRequest(false);
  };

  useEffect(() => {
  }, [swapRequests])

  return (
    <div className="max-w-[410px] min-w-[410px] max-h-full mt-[8px] mr-[8px]">
      <AddNoteModal
        approved={action}
        data={selectedSwapRequest}
        isModalVisible={isAddNoteModalVisible}
        setModalVisible={setAddNoteModalVisible}
        handleRefresh={handleRefresh}
      />
      <CancelModal
        data={selectedSwapRequest}
        isModalVisible={isCancelModalVisible}
        setModalVisible={setIsCancelModalVisible}
        handleRefresh={handleRefresh}
      />
      <SwapShift
        isModalVisible={openSwapRequest}
        setModalVisible={handleCloseNewSwapRequest}
      />
      <div className="mb-[24px]">
        <button
          onClick={handleOpenNewSwapRequest}
          className="bg-white hover:bg-[#EBEEFF] w-[140px] h-[40px] rounded-[40px] text-[13px] font-[500] text-darkBlue border-[1px] border-darkBlue px-[16px] flex items-center justify-center gap-[5px] mr-2"
        >
          {t("SwapRequestB")}
          <span>+</span>
        </button>

      </div>
      <div className="text-[24px] font-[700]">{t("Swap Requests")}</div>
      <div className="overflow-y-scroll scrollbar-hidden h-[85%] flex flex-col xl:ml-0 ml-1">
        {Array.isArray(swapRequests) && swapRequests?.map((each) => (
          <div
            className="bg-[#f7f8f7] rounded-[8px] xl:w-full xl:max-w-full max-w-[82%] max-h-[280px] flex flex-col justify-between my-2 p-3 py-4"
            key={each?._id}
          >
            <div className="w-[100%] flex flex-row justify-between  items-center xl:gap-4 gap-2 mb-4">

              <div className="flex gap-2 align-middle justify-center ">
                <Tooltip
                  text={`${each?.to_user?.first_name ? each?.to_user?.first_name : ""} ${each?.to_user?.last_name ? each?.to_user?.last_name : ""} had a shift ${formatTimeDifference(each?.createdAt)}`}
                >
                  <img alt="warn" src={warn} className="w-[23px]" />
                </Tooltip>
                <div className="flex items-center gap-2 group">
                  {each?.from_user ? (
                    <div className="text-base text-gray-800 group-hover:text-black">
                      <span className="font-bold">From</span>{" "}
                      {each?.from_user?.first_name && each?.from_user?.last_name
                        ? `${each.from_user.first_name} ${each.from_user.last_name}`
                        : "No Profile Data"}
                    </div>
                  ) : each?.to_user ? (
                    <div className="text-base text-gray-800 group-hover:text-black">
                      <span className="font-bold">To</span>{" "}
                      {each?.to_user?.first_name && each?.to_user?.last_name
                        ? `${each.to_user.first_name} ${each.to_user.last_name}`
                        : "No Profile Data"}
                    </div>
                  ) : (
                    <div className="text-base font-bold text-gray-500">No Data Available</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row gap-1 xl:mr-0 ">
                {/* <div className="xl:text-[14px] text-[12px] font-[400] ml-2 text-[#5d6561] ">
                  {!each?.createdswap
                    ? t("requested a day off")
                    : t("Requested a Shift Swap")
                  }
                </div>
                <div className="xl:text-[14px] text-[12px] font-[600]">
                  {formatTimeDifference(each?.createdAt)}
                </div> */}
              </div>

            </div>
            <div className="flex justify-between mb-3">
              <div className="h-[50px] w-[160px] flex flex-col justify-between">
                {each?.from_schedule && (
                  <>
                    <div className="text-[14px] text-[#5d6561]">{t("From")} <span className="text-[14px] font-bold">{each?.from_schedule?.shift?.shift_name}</span></div>

                    <div className="text-[14px] flex items-center w-full">
                      <img alt="calender" src={calender} className="w-[13px]" />
                      <div className="xl:text-[16px] text-[12px] ml-3">
                        {moment(each?.from_schedule?.date).format(
                          "ddd, DD MMM YYYY"
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {each?.to_schedule && (
                <>
                  <div className="w-[60px] h-[40px] text-[#00a843] text-[16px] font-[700] flex items-center justify-center">
                    <img
                      alt="requestBtn"
                      src={requestBtn}
                      className="w-[16px] mt-5"
                    />
                  </div>
                  <div className="h-[50px] w-[160px] flex flex-col justify-between">
                    <div className="text-[14px] text-[#5d6561]">{t("To")} <span className="text-[14px] font-bold">{each?.to_schedule?.shift?.shift_name}</span></div>
                    <div className="text-[14px] flex items-center w-full">
                      <img alt="calender" src={calender} className="w-[13px]" />
                      <div className="xl:text-[16px] text-[12px] ml-3">
                        {moment(each?.to_schedule?.date).format("ddd, DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="flex items-center mb-3">
              <img alt="request" src={request} className="w-[14px]" />
              <div className="xl:text-[16px] text-[12px] ml-2 text-[#5d6561]">
                {each?.message || t("No Message")}
              </div>
            </div>

            <div className="w-full flex items-center justify-between mt-1">
              {each?.status === "rejected" && (
                <>
                  <div className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed">
                    {t("Declined")}
                    <div className="flex items-baseline mt-1 ml-[16px] justify-between">
                      <img
                        alt="redClose"
                        src={redClose}
                        className="w-[16px]"
                      />
                    </div>
                  </div>
                  <div className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] bg-[#E8EBE9] text-[#C7CDC9] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed">
                    {t("Accept")}
                  </div>
                </>
              )}
              {
                each?.from_user?._id !== userId && (
                  <>
                    {each?.status === "pending" && (
                      <>
                        <div
                          onClick={() => handleShowNotes(each, false)}
                          className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121]  text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                        >
                          {t("Decline")}
                        </div>
                        <div
                          onClick={() => handleShowNotes(each, true)}
                          className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#00a843]  text-[#00a843] text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                        >
                          {t("Accept")}
                          <div className="flex items-baseline mt-1 ml-[16px] justify-between"></div>
                        </div>
                      </>
                    )}
                  </>
                )
              }
              {
                each?.from_user?._id === userId && (
                  <>
                    {each?.status === "pending" && (
                      <>
                        <div
                          onClick={() => handleShowCancelModal(each, false)}
                          className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121]  text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                        >
                          {t("Cancel")}
                        </div>
                      </>
                    )}
                  </>
                )
              }

              {/* <div
                onClick={() => handleShowNotes(each)}
                className="w-[60px] h-[40px] rounded-[8px] border border-1 border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] text-[16px] font-[700] flex items-center justify-center cursor-pointer"
              >
                <img alt="message" src={message} className="w-[16px]" />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Requests;
