import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import fetchWithToken from "../../utils/api";
import Spinner from "../../components/spinner";
import NewCalendarDesign from "../../components/new-calendar/newCalendarDesign";
import DashboardSideWrapperComponent from "../../components/dashboardSideWrapperComponent/dashboardSideWrapperComponent";

const Dashboard = () => {
  const UserPermissions = useSelector((state: any) => state?.user?.permissions);
  const hasListSchedule = true;
  const hasListShifts = true;
  const hasListSwapRequests = true;
  const [nextShift, setNextShift] = useState<any>(null);
  const [countdown, setCountdown] = useState("");
  const { t } = useTranslation();
  const [nextShitLoading, setNextShitLoading] = useState<boolean>(false);

  const fetchNextShift = async () => {
    setNextShitLoading(true);
    try {
      const data = await fetchWithToken("/schedules/upcoming", { method: "GET" });
      
      // Sort shifts chronologically
      const sortedShifts = data.data.sort((a: any, b: any) => {
        const aTime = moment.utc(`${a.date.split('T')[0]} ${a.shift.start_time}`);
        const bTime = moment.utc(`${b.date.split('T')[0]} ${b.shift.start_time}`);
        return aTime.diff(bTime);
      });

      setNextShift(sortedShifts[0] || null);
      setNextShitLoading(false);
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
      setNextShitLoading(false);
    }
  };

  useEffect(() => {
    fetchNextShift();
  }, []);

  useEffect(() => {
    if (!nextShift?.shift?.start_time) return;

    const shiftStart = moment.utc(
      `${nextShift.date.split("T")[0]} ${nextShift.shift.start_time}`
    );

  const updateCountdown = () => {
  const now = moment.utc();
  const duration = moment.duration(shiftStart.diff(now));

  if (duration.asSeconds() > 0) {
    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const timeParts = [
      String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0")
    ];

    const displayDays = totalDays === 1 ? `${totalDays} ${t("day")}` : totalDays > 1 ? `${totalDays} ${t("day_plural")}` : "";
    setCountdown(displayDays ? `${displayDays} ${timeParts.join(":")}` : timeParts.join(":"));
  } else {
    setCountdown("00:00:00");
    fetchNextShift();
  }
};


    const timer = setInterval(updateCountdown, 1000);
    updateCountdown();
    return () => clearInterval(timer);
  }, [nextShift, t]);

  return (
    <div className="w-full h-full flex-col flex flex-1 overflow-hidden px-[5px]">
      {hasListShifts && (
        <div className="border-2 border-[#138EE9] lg:h-[55px] h-auto flex 
        flex-col md:flex-row items-center justify-center font-[500] text-[16px] 
        md:text-[24px] rounded-[20px] mt-[4px] mb-[16px] px-4 py-2 md:py-0">
          {nextShitLoading ? (
            <Spinner />
          ) : (
            <>
              <span>{t("NextShiftMessage")}</span>
              <div className="flex-grow text-[#636363] text-right">
                <span className="font-[700] mx-2 text-[#239FFC]">
                  {countdown || "00:00:00"}
                </span>
                {t("NextShiftMessage.Hours")}
              </div>
            </>
          )}
        </div>
      )}
      
      <div className="w-full h-full flex flex-col gap-10">
        <DashboardSideWrapperComponent />
        {hasListSchedule && <NewCalendarDesign />}
      </div>
    </div>
  );
};

export default Dashboard;