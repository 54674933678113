import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import Location from "../modals/location";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import Spinner from "../spinner";

interface Location {
  _id: number;
  name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}

interface LocationsProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Locations: React.FC<LocationsProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [content, setContent] = useState<string>("");
  const [locations, setLocations] = useState<Location[]>([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);

  const fetchLocations = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithToken(`/locations?page=${currentPage}`, {
        method: "GET",
      });
      setLocations(response?.data);
      setCurrentPage(response?.page);
      setTotalPages(Math.max(1, Math.ceil(response.total / response?.limit)));
      setTotalCount(response?.limit)
      setIsLoading(false);

    } catch (error) {
      console.error("Failed to fetch locations:", error);
      setIsLoading(false);

    }
  };

  useEffect(() => {
    fetchLocations();
  }, [currentPage, count]);

  return (
    <>
      <Location
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchLocations={fetchLocations}
      />
      <DeleteModal
        route="locations"
        //content={content}
        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchLocations}
      />
    {isLoading ? <Spinner/> : 
          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6 text-center">
        <TableHead className="hidden lg:table-header-group text-center">
          <TableRow className="bg-white text-center">
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center justify-center text-black">
                {t("Number")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center   justify-center">
                {t("Hospital")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center  justify-center">
                {t("Address")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center  justify-center">
                {t("City")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center  justify-center">
                {t("State")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center justify-center">
                {t("Country")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black">
              <div className="flex items-center  justify-center">
                {t("PostalCode")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 text-black"></TableHeader>
          </TableRow>
        </TableHead>

        <TableBody className="w-full text-center">
          {Array.isArray(locations) &&
            locations?.map((each, index) => {
              const rowNumber = index + 1 + (currentPage - 1) * count;
              return (
                <TableRow
                  key={each?._id}
                  className="flex flex-col lg:table-row mb-4 lg:mb-0 border lg:border-none w-full text-center  cursor-pointer hover:bg-slate-100"
                >
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Number")}
                  </TableCell>
                  <TableCell>{rowNumber}</TableCell>
                  
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Hospital")}
                  </TableCell>
                  <TableCell>{each?.name}</TableCell>

                  
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Address")}
                  </TableCell>
                  <TableCell>
                    {each?.addr_one}
                    <div className="text-[#5D6561]">{each?.addr_two}</div>
                  </TableCell>

                  
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("City")}
                  </TableCell>
                  <TableCell>{each?.city}</TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("State")}
                  </TableCell>
                  <TableCell>{each?.state}</TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Country")}
                  </TableCell>
                  <TableCell>{each?.country}</TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("PostalCode")}
                  </TableCell>
                  <TableCell>{each?.postal_code}</TableCell>

                  <TableCell className="flex justify-end w-full mt-4 lg:mt-0">
                    <div
                      onClick={() => setModalVisible(each._id)}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                    </div>
                    <div
                      onClick={() => {
                        setDeleteRequestModal(each._id);
                        setContent(`schedule of ${each?.name}`);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteIcon}
                        className="w-6 h-6"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    }
    </>
  );
};

export default Locations;
